import { verifyAndRelayRequest, verifyAndRelayResourcesRequest } from '../utils/index';
const axios = require('axios');

// export async function fileCreate(access, name, type) {
//   return verifyAndRelayRequest({
//     route: '/file/create',
//     method: 'POST',
//     protected: true,
//     data: {
//       access, name, type,
//     },
//   });
// }

// export async function fileRead(filter, includeUrl, options = {}) {
//   return verifyAndRelayRequest({
//     route: '/file/read',
//     method: 'POST',
//     data: {
//       filter,
//       includeUrl,
//       options,
//     },
//     protected: true,
//   });
// }

export async function fileUpdate(filter, update) {
  return verifyAndRelayRequest({
    route: '/file/update',
    method: 'PUT',
    protected: true,
    data: {
      filter,
      update
    }
  });
}

export async function fileDelete(filter) {
  return verifyAndRelayRequest({
    route: '/file/delete',
    method: 'DELETE',
    protected: true,
    data: { filter }
  });
}

export async function fileUpload(url, fileType, file) {
  return axios(url, {
    method: 'PUT',
    headers: {
      'Content-Type': fileType
    },
    data: file
  });
}

// module.exports = {
//   fileCreate,
//   fileRead,
//   fileUpdate,
//   fileDelete,
//   fileUpload,
// };

export async function fileCreate(access, name, type) {
  return verifyAndRelayResourcesRequest({
    route: '/file-create',
    method: 'POST',
    protected: true,
    data: {
      file: {
        name,
        type,
        access
      }
    }
  });
}

export async function fileRead(filter, includeUrl, options = {}) {
  // includeUrl is unused -> all files will always include url

  // transform _id string to array of strings
  if (typeof filter._id === 'string' || filter._id instanceof String) {
    filter._id = [filter._id];
  }

  // transform access string to array of strings
  if (typeof filter.access === 'string' || filter.access instanceof String) {
    filter.access = [filter.access];
  }

  // transform type string to array of strings
  if (typeof filter.type === 'string' || filter.type instanceof String) {
    filter.type = [filter.type];
  }

  return verifyAndRelayResourcesRequest({
    route: '/file-read',
    method: 'POST',
    data: {
      filter,
      options
    },
    protected: true
  });
}
