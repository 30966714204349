import axios from 'axios';
import sessionStorage from 'sessionstorage';
import AlleviError from './error';

let apiURL = 'http://prod-api.allevi.app';
let resourcesURL = 'http://resources-prod.allevi.app';
let authToken = sessionStorage.getItem('id_token');

export function setApiUrl(url) {
  apiURL = url;
}

export function setResourcesUrl(url) {
  resourcesURL = url;
}

export function setAuthToken(token) {
  authToken = token;
}

function isAuthTokenSet() {
  return authToken !== '';
}

function getInvalidAuthTokenResponse() {
  throw new AlleviError(0, 'Auth token is not set');
}

export async function sendRequest(requestParams, contentType, useResourcesUrl = false) {
  const requestUrl = useResourcesUrl ? resourcesURL : apiURL;

  let request = {
    url: requestUrl + requestParams.route,
    method: requestParams.method,
    json: true,
    withCredentials: true
  };

  if (contentType === 'json') {
    request = Object.assign(request, {
      headers: {
        'Content-method': 'application/json'
      }
    });
  } else if (contentType === 'formData') {
    request = Object.assign(request, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  if (requestParams.protected) {
    request = Object.assign(request, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
  }

  if (requestParams.method === 'POST' || requestParams.method === 'DELETE' || requestParams.method === 'PUT') {
    Object.assign(request, { data: requestParams.data });
  }

  try {
    const response = await axios(request);
    if (!response) return { statusCode: 500, code: 'NO_RESPONSE' };

    return { statusCode: response.status, ...response.data };
  } catch (error) {
    // if (error.response.status === 401) {
    //   throw new AlleviError('UNAUTHORIZED', 'The user is not permitted to access this resource');
    // }
    if (error.response) {
      throw new AlleviError(
        error.response.data.code, // e.g. "UNAUTHORIZED_ACCESS_TOKEN_ERROR"
        error.response.data.message, // e.g. "Invalid or expired access token"
        error.response.status // e.g. 401
      );
    } else if (error.request) {
      throw new AlleviError('NO_RESPONSE', 'The API returned no response', 500);
    } else {
      throw new AlleviError('GENERAL', 'Error sending request to API', 500);
    }
  }
}

export async function verifyAndRelayRequest(request, contentType = 'json') {
  if (!isAuthTokenSet()) {
    return getInvalidAuthTokenResponse();
  }
  return sendRequest(request, contentType);
}

// module.exports = {
//   sendRequest,
//   verifyAndRelayRequest,
//   setAuthToken,
// };

// the following is for dev purposes

export async function sendRequestResources(requestParams, contentType) {
  let request = {
    url: resourcesURL + requestParams.route,
    method: requestParams.method,
    json: true,
    withCredentials: true
  };

  if (contentType === 'json') {
    request = Object.assign(request, {
      headers: {
        'Content-method': 'application/json'
      }
    });
  } else if (contentType === 'formData') {
    request = Object.assign(request, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  if (requestParams.protected) {
    request = Object.assign(request, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
  }

  if (requestParams.method === 'POST' || requestParams.method === 'DELETE' || requestParams.method === 'PUT') {
    Object.assign(request, { data: requestParams.data });
  }

  try {
    const response = await axios(request);
    if (!response) return { statusCode: 500, code: 'NO_RESPONSE' };

    return { statusCode: response.status, ...response.data };
  } catch (error) {
    // if (error.response.status === 401) {
    //   throw new AlleviError('UNAUTHORIZED', 'The user is not permitted to access this resource');
    // }
    if (error.response) {
      throw new AlleviError(
        error.response.data.code, // e.g. "UNAUTHORIZED_ACCESS_TOKEN_ERROR"
        error.response.data.message, // e.g. "Invalid or expired access token"
        error.response.status // e.g. 401
      );
    } else if (error.request) {
      throw new AlleviError('NO_RESPONSE', 'The Resources API returned no response', 500);
    } else {
      throw new AlleviError('GENERAL', 'Error sending request to resources API', 500);
    }
  }
}

export async function verifyAndRelayResourcesRequest(request, contentType = 'json') {
  if (!isAuthTokenSet()) {
    return getInvalidAuthTokenResponse();
  }
  return sendRequest(request, contentType, true);
}
