import produce, { castDraft } from 'immer';
import { AlleviClientState } from '../../../apis/allevi-adapter-wrapper';
import {
  adapterClientNoResponse,
  adapterClientStateReceived,
  adapterNoResponse,
  adapterStateReceived
} from '../actions/adapterActions';
import { assertActionType } from '../util/assertActionType';

export interface AdapterState {
  readonly state: 'DISCONNECTED' | 'CONNECTED_TO_PRINTER' | 'CONNECTED_TO_CLIENT';
  readonly serialNumber: string | undefined;
  readonly clientState: AlleviClientState | undefined;
  readonly clientVersion: string | undefined;
}

const initialState: AdapterState = {
  state: 'DISCONNECTED',
  serialNumber: undefined,
  clientState: undefined,
  clientVersion: undefined
};

export default (state = initialState, action: any): AdapterState => {
  switch (action.type) {
    case 'ADAPTER_CLIENT_STATE_RECEIVED':
      assertActionType(action, adapterClientStateReceived);
      return produce(state, draft => {
        if (draft.state === 'DISCONNECTED') draft.state = 'CONNECTED_TO_CLIENT';
        draft.clientState = castDraft(action.state);
        draft.clientVersion = action.state.clientVersion;
      });
    case 'ADAPTER_CLIENT_NO_RESPONSE':
      assertActionType(action, adapterClientNoResponse);
      return produce(state, draft => {
        draft.state = 'DISCONNECTED';
        draft.clientState = undefined;
        draft.clientVersion = undefined;
        draft.serialNumber = undefined;
      });

    case 'ADAPTER_STATE_RECEIVED':
      assertActionType(action, adapterStateReceived);
      return produce(state, draft => {
        draft.state = 'CONNECTED_TO_PRINTER';
        draft.serialNumber = action.state.state.serialNumber;
      });

    case 'ADAPTER_NO_RESPONSE':
      assertActionType(action, adapterNoResponse);
      return produce(state, draft => {
        if (draft.state === 'CONNECTED_TO_PRINTER') draft.state = 'CONNECTED_TO_CLIENT';
        draft.serialNumber = undefined;
      });

    default:
      return state;
  }
};
