import { verifyAndRelayRequest } from '../utils/index';

export async function printerCreate(
  access,
  name,
  serialNumber,
  printerData,
  extruderData,
  registrationData,
  createGCP = true,
  createBalena = true
) {
  return verifyAndRelayRequest({
    route: '/printer/create',
    method: 'POST',
    protected: true,
    data: {
      access,
      name,
      serialNumber,
      printerData,
      extruderData,
      registrationData,
      createGCP,
      createBalena
    }
  });
}

export async function printerRead(filter, options = {}) {
  return verifyAndRelayRequest({
    route: '/printer/read',
    method: 'POST',
    data: {
      filter,
      options
    },
    protected: true
  });
}

export async function printerUpdate(filter, update) {
  return verifyAndRelayRequest({
    route: '/printer/update',
    method: 'PUT',
    protected: true,
    data: {
      filter,
      update
    }
  });
}

export async function printerDelete(filter) {
  return verifyAndRelayRequest({
    route: '/printer/delete',
    method: 'DELETE',
    protected: true,
    data: { filter }
  });
}

export async function printerRegister(serialNumber) {
  return verifyAndRelayRequest({
    route: '/printer/register',
    method: 'POST',
    data: {
      serialNumber
    },
    protected: true
  });
}

export async function printerUnregister(serialNumber) {
  return verifyAndRelayRequest({
    route: '/printer/unregister',
    method: 'POST',
    data: {
      serialNumber
    },
    protected: true
  });
}

export async function printerCheckStatus(serialNumbers, getDeviceData = false) {
  return verifyAndRelayRequest({
    route: '/printer/check/status',
    method: 'POST',
    data: {
      serialNumbers,
      getDeviceData
    },
    protected: true
  });
}

export async function printerSendCommand(serialNumber, command) {
  return verifyAndRelayRequest({
    route: '/printer/send/command',
    method: 'POST',
    data: {
      serialNumber,
      command
    },
    protected: true
  });
}

export async function printerEnvvarRead(serialNumber, key = []) {
  return verifyAndRelayRequest({
    route: '/printer/envvar/read',
    method: 'POST',
    data: {
      serialNumber,
      key
    },
    protected: true
  });
}

export async function printerEnvvarUpdate(serialNumber, key, value) {
  return verifyAndRelayRequest({
    route: '/printer/envvar/update',
    method: 'POST',
    data: {
      serialNumber,
      key,
      value
    },
    protected: true
  });
}

export async function printerDeviceUpdate(serialNumber, updateRelease = false, updateOS = false) {
  return verifyAndRelayRequest({
    route: '/printer/device/update',
    method: 'POST',
    data: {
      serialNumber,
      updateRelease,
      updateOS
    },
    protected: true
  });
}

export async function printerAddOfflineSupport(serialNumber, checkOnline = true) {
  return verifyAndRelayRequest({
    route: '/printer/add/offline/support',
    method: 'POST',
    data: {
      serialNumber,
      checkOnline
    },
    protected: true
  });
}

// module.exports = {
//   printerCreate,
//   printerRead,
//   printerUpdate,
//   printerDelete,
// };
