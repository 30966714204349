import { ThunkDispatch } from 'redux-thunk';
import { Material, materialRead } from '../../../apis/allevi-api-wrapper';

// Get material
const getMaterialAction = (materialId: string) =>
  ({
    type: 'GET_MATERIAL',
    materialId
  } as const);

const getMaterialSuccess = (material: Material) =>
  ({
    type: 'GET_MATERIAL_SUCCESS',
    material
  } as const);

const getMaterialFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_MATERIAL_FAILURE',
    statusCode,
    message
  } as const);

export function getMaterial(materialId: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getMaterialAction(materialId));

    return materialRead({ _id: materialId })
      .then(e => {
        if (e.statusCode === 200 && e.material) {
          dispatch(getMaterialSuccess(e.material[0]));
          return { material: e.material[0], success: true } as const;
        }

        dispatch(getMaterialFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getMaterialFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Get all materials
const getAllMaterialsAction = () =>
  ({
    type: 'GET_ALL_MATERIALS'
  } as const);

const getAllMaterialsSuccess = (materials: Material[]) =>
  ({
    type: 'GET_ALL_MATERIALS_SUCCESS',
    materials
  } as const);

const getAllMaterialsFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_ALL_MATERIALS_FAILURE',
    statusCode,
    message
  } as const);

export function getAllMaterials(skip = 0, limit = 1000, sort = {}) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getAllMaterialsAction());

    return materialRead({}, { skip, limit, sort })
      .then(e => {
        if (e.statusCode === 200 && e.materials) {
          dispatch(getAllMaterialsSuccess(e.materials));
          return { material: e.materials, success: true } as const;
        }

        dispatch(getAllMaterialsFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getAllMaterialsFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Get all materials by printer
const getAllMaterialsByPrinterAction = (modelNumber: number) =>
  ({
    type: 'GET_ALL_MATERIALS_BY_PRINTER',
    modelNumber
  } as const);

const getAllMaterialsByPrinterSuccess = (materials: Material[]) =>
  ({
    type: 'GET_ALL_MATERIALS_BY_PRINTER_SUCCESS',
    materials
  } as const);

const getAllMaterialsByPrinterFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_ALL_MATERIALS_BY_PRINTER_FAILURE',
    statusCode,
    message
  } as const);

export function getAllMaterialsByPrinter(modelNumber: number) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getAllMaterialsByPrinterAction(modelNumber));

    //@ts-expect-error
    return materialRead({ printerData: { supportedPrinters: modelNumber } })
      .then(e => {
        if (e.statusCode === 200 && e.materials) {
          dispatch(getAllMaterialsByPrinterSuccess(e.materials));
          return { success: true };
        }
        dispatch(getAllMaterialsByPrinterFailure(e.statusCode, e.message));
        return { success: false };
      })
      .catch(e => {
        dispatch(getAllMaterialsByPrinterFailure(e.statusCode, e.message));
        return { success: false };
      });
  };
}
