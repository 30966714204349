import Immutable from 'seamless-immutable';
import { Nuc } from '../../../apis/allevi-api-wrapper';

interface NucState {
  readonly status: 'idle' | 'fetching';
  readonly serialNumber: string;
  readonly firstRun: boolean;
  readonly nucs: readonly Nuc[];
  readonly tokens: Readonly<Record<string, string>>;
}

const initialState = Immutable<NucState>({
  status: 'idle',
  serialNumber: '',
  firstRun: true,
  nucs: [],
  tokens: {}
});

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'IS_NUC_FIRST_RUN':
      return state.set('status', 'fetching');

    case 'IS_NUC_FIRST_RUN_FAILURE':
      return state.set('status', 'idle');

    case 'IS_NUC_FIRST_RUN_SUCCESS':
      if (typeof action.firstRun === 'undefined') {
        return state;
      }

      state = state.set('status', 'idle');
      state = state.set('firstRun', action.firstRun);

      return state;

    case 'GET_NUC_SUCCESS':
      if (!action.nucs) {
        return state;
      }

      return state.set('nucs', action.nucs);

    case 'GENERATE_NUC_PRINTER_TOKEN_SUCCESS':
      if (!action.printerToken || !action.printerSerialNumber) {
        return state;
      }

      return state.setIn(['tokens', action.printerSerialNumber], action.printerToken);

    default:
      return state;
  }
};
