import { ThunkDispatch } from 'redux-thunk';
import {
  nucCreate,
  nucRegister,
  nucUnregister,
  nucRead,
  nucIsFirstRun,
  nucValidateInitializationToken,
  nucGeneratePrinterToken,
  nucAddPrinter,
  nucDeviceUpdate,
  nucPlanActivate,
  nucReset,
  nucUserPasswordReset,
  Nuc
} from '../../../apis/allevi-api-wrapper';

// Create NUC device
const createNUCAction = (name: string, serialNumber: string) =>
  ({
    type: 'CREATE_NUC',
    name,
    serialNumber
  } as const);

const createNUCSuccess = (nuc: Nuc) =>
  ({
    type: 'CREATE_NUC_SUCCESS',
    nuc
  } as const);

const createNUCFailure = (statusCode: number, message: string) =>
  ({
    type: 'CREATE_NUC_FAILURE',
    statusCode,
    message
  } as const);

export function createNUC(name: string, serialNumber: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(createNUCAction(name, serialNumber));

    return nucCreate('DEFAULT', name, serialNumber, { modelNumber: 'NUC8CCHB' })
      .then(e => {
        if (e.statusCode === 200 && e.nuc) {
          dispatch(createNUCSuccess(e.nuc));
          return { success: true } as const;
        }
        dispatch(createNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(createNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Register NUC device
const registerNUCAction = (serialNumber: string) =>
  ({
    type: 'REGISTER_NUC',
    serialNumber
  } as const);

const registerNUCSuccess = (token: string) =>
  ({
    type: 'REGISTER_NUC_SUCCESS',
    token
  } as const);

const registerNUCFailure = (statusCode: number, message: string) =>
  ({
    type: 'REGISTER_NUC_FAILURE',
    statusCode,
    message
  } as const);

export function registerNUC(serialNumber: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(registerNUCAction(serialNumber));

    return nucRegister(serialNumber)
      .then(e => {
        if (e.statusCode === 200 && e.nuc && e.token) {
          dispatch(registerNUCSuccess(e.token));
          return { success: true } as const;
        }
        dispatch(registerNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(registerNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Unregister NUC device
const unregisterNUCAction = (serialNumber: string) =>
  ({
    type: 'UNREGISTER_NUC',
    serialNumber
  } as const);

const unregisterNUCSuccess = (token: string) =>
  ({
    type: 'UNREGISTER_NUC_SUCCESS',
    token
  } as const);

const unregisterNUCFailure = (statusCode: number, message: string) =>
  ({
    type: 'UNREGISTER_NUC_FAILURE',
    statusCode,
    message
  } as const);

export function unregisterNUC(serialNumber: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(unregisterNUCAction(serialNumber));

    return nucUnregister(serialNumber)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(unregisterNUCSuccess(e.token!));
          return { success: true } as const;
        }
        dispatch(unregisterNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(unregisterNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Get NUC devices for account
const getNUCAction = () =>
  ({
    type: 'GET_NUC'
  } as const);

const getNUCSuccess = (nucs: Nuc[]) =>
  ({
    type: 'GET_NUC_SUCCESS',
    nucs
  } as const);

const getNUCFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_NUC_FAILURE',
    statusCode,
    message
  } as const);

export function getNUC() {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getNUCAction());

    return nucRead({})
      .then(e => {
        if (e.statusCode === 200 && e.nuc) {
          dispatch(getNUCSuccess(e.nuc));
          return { success: true } as const;
        }
        dispatch(getNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Determine if NUC first run
const isNUCFirstRunAction = () =>
  ({
    type: 'IS_NUC_FIRST_RUN'
  } as const);

const isNUCFirstRunSuccess = (firstRun: boolean) =>
  ({
    type: 'IS_NUC_FIRST_RUN_SUCCESS',
    firstRun
  } as const);

const isNUCFirstRunFailure = (statusCode: number, message: string) =>
  ({
    type: 'IS_NUC_FIRST_RUN_FAILURE',
    statusCode,
    message
  } as const);

export function isNUCFirstRun() {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(isNUCFirstRunAction());

    return nucIsFirstRun()
      .then(e => {
        if (e.statusCode === 200 && typeof e.firstRun !== 'undefined') {
          dispatch(isNUCFirstRunSuccess(e.firstRun));
          return { success: true, firstRun: e.firstRun } as const;
        }
        dispatch(isNUCFirstRunFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(isNUCFirstRunFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Update NUC
const updateNUCAction = (serialNumber: string, updateRelease: boolean, updateOS: boolean) =>
  ({
    type: 'UPDATE_NUC',
    serialNumber,
    updateRelease,
    updateOS
  } as const);

const updateNUCSuccess = () =>
  ({
    type: 'UPDATE_NUC_SUCCESS'
  } as const);

const updateNUCFailure = (statusCode: number, message: string) =>
  ({
    type: 'UPDATE_NUC_FAILURE',
    statusCode,
    message
  } as const);

export function updateNUC(serialNumber: string, updateRelease = false, updateOS = false) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(updateNUCAction(serialNumber, updateRelease, updateOS));

    return nucDeviceUpdate(serialNumber, updateRelease, updateOS)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(updateNUCSuccess());
          return { success: true, firstRun: e.firstRun } as const;
        }
        dispatch(updateNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(updateNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Validate NUC initialization token
const validateNUCInitializationTokenAction = (serialNumber: string, token: string) =>
  ({
    type: 'VALIDATE_NUC_INITIALIZATION_TOKEN',
    serialNumber,
    token
  } as const);

const validateNUCInitializationTokenSuccess = () =>
  ({
    type: 'VALIDATE_NUC_INITIALIZATION_TOKEN_SUCCESS'
  } as const);

const validateNUCInitializationTokenFailure = (statusCode: number, message: string) =>
  ({
    type: 'VALIDATE_NUC_INITIALIZATION_TOKEN_FAILURE',
    statusCode,
    message
  } as const);

export function validateNUCInitializationToken(serialNumber: string, token: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(validateNUCInitializationTokenAction(serialNumber, token));

    return nucValidateInitializationToken(serialNumber, token)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(validateNUCInitializationTokenSuccess());
          return { success: true, firstRun: e.firstRun } as const;
        }
        dispatch(validateNUCInitializationTokenFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(validateNUCInitializationTokenFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Generate printer token for NUC
const generateNUCPrinterTokenAction = (serialNumber: string, printerSerialNumber: string) =>
  ({
    type: 'GENERATE_NUC_PRINTER_TOKEN',
    serialNumber,
    printerSerialNumber
  } as const);

const generateNUCPrinterTokenSuccess = (printerSerialNumber: string, printerToken: string) =>
  ({
    type: 'GENERATE_NUC_PRINTER_TOKEN_SUCCESS',
    printerSerialNumber,
    printerToken
  } as const);

const generateNUCPrinterTokenFailure = (statusCode: number, message: string) =>
  ({
    type: 'GENERATE_NUC_PRINTER_TOKEN_FAILURE',
    statusCode,
    message
  } as const);

export function generateNUCPrinterToken(serialNumber: string, printerSerialNumber: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(generateNUCPrinterTokenAction(serialNumber, printerSerialNumber));

    return nucGeneratePrinterToken(serialNumber, printerSerialNumber)
      .then(e => {
        if (e.statusCode === 200 && e.token) {
          dispatch(generateNUCPrinterTokenSuccess(printerSerialNumber, e.token));
          return { success: true, printerSerialNumber, printerToken: e.token } as const;
        }
        dispatch(generateNUCPrinterTokenFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(generateNUCPrinterTokenFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Generate printer token for NUC
const addNUCPrinterAction = (token: string, name: string) =>
  ({
    type: 'ADD_NUC_PRINTER',
    token,
    name
  } as const);

const addNUCPrinterSuccess = (printer: TSFixMe) =>
  ({
    type: 'ADD_NUC_PRINTER_SUCCESS',
    printer
  } as const);

const addNUCPrinterFailure = (statusCode: number, message: string) =>
  ({
    type: 'ADD_NUC_PRINTER_FAILURE',
    statusCode,
    message
  } as const);

export function addNucPrinter(token: string, name: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(addNUCPrinterAction(token, name));

    return nucAddPrinter(token, name)
      .then(e => {
        if (e.statusCode === 200 && e.printer) {
          dispatch(addNUCPrinterSuccess(e.printer));
          return { success: true, printer: e.printer } as const;
        }
        dispatch(addNUCPrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(addNUCPrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Activate a software subscription on a NUC
const activateNUCPlanAction = (initializationToken: string) =>
  ({
    type: 'ACTIVATE_NUC_PLAN',
    initializationToken
  } as const);

const activateNUCPlanSuccess = () =>
  ({
    type: 'ACTIVATE_NUC_PLAN_SUCCESS'
  } as const);

const activateNUCPlanFailure = (statusCode: number, message: string) =>
  ({
    type: 'ACTIVATE_NUC_PLAN_FAILURE',
    statusCode,
    message
  } as const);

export function activateNUCPlan(initializationToken: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(activateNUCPlanAction(initializationToken));

    return nucPlanActivate(initializationToken)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(activateNUCPlanSuccess());
          return { success: true } as const;
        }
        dispatch(activateNUCPlanFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(activateNUCPlanFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Reset a user password on the NUC
const resetNUCUserPasswordAction = (initializationToken: string, email: string) =>
  ({
    type: 'RESET_NUC_USER_PASSWORD',
    initializationToken,
    email
  } as const);

const resetNUCUserPasswordSuccess = () =>
  ({
    type: 'RESET_NUC_USER_PASSWORD'
  } as const);

const resetNUCUserPasswordFailure = (statusCode: number, message: string) =>
  ({
    type: 'RESET_NUC_USER_PASSWORD',
    statusCode,
    message
  } as const);

export function resetNUCUserPassword(initializationToken: string, email: string, newPassword: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(resetNUCUserPasswordAction(initializationToken, email));

    return nucUserPasswordReset(initializationToken, email, newPassword)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(resetNUCUserPasswordSuccess());
          return { success: true } as const;
        }
        dispatch(resetNUCUserPasswordFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(resetNUCUserPasswordFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Factory reset a NUC
const resetNUCAction = (initializationToken: string) =>
  ({
    type: 'RESET_NUC',
    initializationToken
  } as const);

const resetNUCSuccess = () =>
  ({
    type: 'RESET_NUC'
  } as const);

const resetNUCFailure = (statusCode: number, message: string) =>
  ({
    type: 'RESET_NUC',
    statusCode,
    message
  } as const);

export function resetNUC(initializationToken: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(resetNUCAction(initializationToken));

    return nucReset(initializationToken)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(resetNUCSuccess());
          return { success: true } as const;
        }
        dispatch(resetNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(resetNUCFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}
