import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ExtractRouteParams } from 'react-router';

interface PrivateRouteProps<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>
> extends Omit<RouteProps<Path, Params>, 'render'> {}

export const PrivateRoute = <
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>
>({
  component: AComponent,
  ...rest
}: PrivateRouteProps<Path, Params>) => (
  <Route
    {...rest}
    render={props =>
      sessionStorage.getItem('id_token') ? (
        //@ts-expect-error
        <AComponent {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
