import { ThunkDispatch } from 'redux-thunk';
import {
  setAuthToken,
  organizationCreate,
  organizationRead,
  // organizationDelete,
  organizationTeamAddAdministrator,
  organizationTeamRemoveAdministrator,
  organizationTeamAddMember,
  organizationTeamRemoveMember,
  organizationTeamSwitch,
  organizationTeamAdd,
  organizationTeamResourceTransfer,
  organizationTeamResourceCount,
  organizationTeamLeave,
  organizationTeamDelete,
  organizationPlanActivate,
  organizationSubscriptionTransfer,
  Organization,
  Resources
} from '../../../apis/allevi-api-wrapper';

// Create organization
const createOrganizationAction = (name: string) =>
  ({
    type: 'CREATE_ORGANIZATION',
    name
  } as const);

const createOrganizationSuccess = (organization: Organization) =>
  ({
    type: 'CREATE_ORGANIZATION_SUCCESS',
    organization
  } as const);

const createOrganizationFailure = (statusCode: number, message: string) =>
  ({
    type: 'CREATE_ORGANIZATION_FAILURE',
    statusCode,
    message
  } as const);

export function createOrganization(name: string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(createOrganizationAction(name));

    return organizationCreate(name)
      .then(e => {
        if (e.statusCode === 200 && e.organization) {
          dispatch(createOrganizationSuccess(e.organization));
          return { success: true, organization: e.organization } as const;
        }
        dispatch(createOrganizationFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(createOrganizationFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Read organization
const getOrganizationAction = () =>
  ({
    type: 'GET_ORGANIZATION'
  } as const);

const getOrganizationSuccess = (organization: Organization) =>
  ({
    type: 'GET_ORGANIZATION_SUCCESS',
    organization
  } as const);

const getOrganizationFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_ORGANIZATION_FAILURE',
    statusCode,
    message
  } as const);

export function getOrganization() {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getOrganizationAction());

    return organizationRead({})
      .then(e => {
        if (e.statusCode === 200 && e.organization) {
          dispatch(getOrganizationSuccess(e.organization));
          return { success: true } as const;
        }
        dispatch(getOrganizationFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getOrganizationFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Create team
const createTeamAction = (name: string) =>
  ({
    type: 'CREATE_TEAM',
    name
  } as const);

const createTeamSuccess = (team: string) =>
  ({
    type: 'CREATE_TEAM_SUCCESS',
    team
  } as const);

const createTeamFailure = (statusCode: number, message: string) =>
  ({
    type: 'CREATE_TEAM_FAILURE',
    statusCode,
    message
  } as const);

export function createTeam(name: string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(createTeamAction(name));

    return organizationTeamAdd(name)
      .then(e => {
        if (e.statusCode === 200 && e.team) {
          dispatch(createTeamSuccess(e.team));
          return { success: true } as const;
        }
        dispatch(createTeamFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(createTeamFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// // Delete organization (and all teams therein)
// const deleteOrganizationAction = (organization) => ({
//   type: 'DELETE_ORGANIZATION',
//   organization,
// });
//
// const deleteOrganizationSuccess = () => ({
//   type: 'DELETE_ORGANIZATION_SUCCESS',
// });
//
// const deleteOrganizationFailure = (statusCode:number, message:string) => ({
//   type: 'DELETE_ORGANIZATION_FAILURE',
//   statusCode,
//   message,
// });
//
// export function deleteOrganization(organization) {
//   return async (dispatch) => {
//     dispatch(deleteOrganizationAction(organization));
//
//     return organizationDelete({ _id: organization })
//       .then(e => {
//         if (e.statusCode === 200) {
//           dispatch(deleteOrganizationSuccess());
//           return { success: true } as const;
//         }
//         dispatch(deleteOrganizationFailure(e.statusCode, e.message));
//         return { success: false } as const;
//       })
//       .catch(e => {
//         dispatch(deleteOrganizationFailure(e.statusCode, e.message));
//         return { success: false } as const;
//       });
//   };
// }

// Switch teams
const switchTeamAction = (organization: string, team: string) =>
  ({
    type: 'SWITCH_TEAM',
    organization,
    team
  } as const);

const switchTeamSuccess = (token: string) =>
  ({
    type: 'SWITCH_TEAM_SUCCESS',
    token
  } as const);

const switchTeamFailure = (statusCode: number, message: string) =>
  ({
    type: 'SWITCH_TEAM_FAILURE',
    statusCode,
    message
  } as const);

export function switchTeam(organization: string, team: string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(switchTeamAction(organization, team));

    return organizationTeamSwitch(organization, team)
      .then(e => {
        if (e.statusCode === 200 && e.token) {
          dispatch(switchTeamSuccess(e.token));
          // Store token in browser storage
          sessionStorage.setItem('id_token', e.token);
          // Set token for API wrapper
          setAuthToken(e.token);
          return { success: true, token: e.token } as const;
        }
        dispatch(switchTeamFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(switchTeamFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Add a member to a team
const addTeamMemberAction = (emails: string[]) =>
  ({
    type: 'ADD_TEAM_MEMBER',
    team: emails
  } as const);

const addTeamMemberSuccess = (emails: string[]) =>
  ({
    type: 'ADD_TEAM_MEMBER_SUCCESS',
    emails
  } as const);

const addTeamMemberFailure = (statusCode: number, message: string) =>
  ({
    type: 'ADD_TEAM_MEMBER_FAILURE',
    statusCode,
    message
  } as const);

export function addTeamMember(emailsArg: string[] | string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    let emails: string[];
    if (Array.isArray(emailsArg)) {
      emails = emailsArg;
    } else {
      emails = [emailsArg];
    }

    dispatch(addTeamMemberAction(emails));

    return organizationTeamAddMember(emails)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(addTeamMemberSuccess(emails));
          return { success: true } as const;
        }
        dispatch(addTeamMemberFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(addTeamMemberFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Remove member from a team
const removeTeamMemberAction = (emails: string[]) =>
  ({
    type: 'REMOVE_TEAM_MEMBER',
    team: emails
  } as const);

const removeTeamMemberSuccess = (emails: string[]) =>
  ({
    type: 'REMOVE_TEAM_MEMBER_SUCCESS',
    emails
  } as const);

const removeTeamMemberFailure = (statusCode: number, message: string) =>
  ({
    type: 'REMOVE_TEAM_MEMBER_FAILURE',
    statusCode,
    message
  } as const);

export function removeTeamMember(emailsArg: string | string[]) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    let emails: string[];
    if (Array.isArray(emailsArg)) {
      emails = emailsArg;
    } else {
      emails = [emailsArg];
    }

    dispatch(removeTeamMemberAction(emails));

    return organizationTeamRemoveMember(emails)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(removeTeamMemberSuccess(emails));
          return { success: true } as const;
        }
        dispatch(removeTeamMemberFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(removeTeamMemberFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Add administrator to a team
const addTeamAdminAction = (emails: string[]) =>
  ({
    type: 'ADD_TEAM_ADMIN',
    team: emails
  } as const);

const addTeamAdminSuccess = (emails: string[]) =>
  ({
    type: 'ADD_TEAM_ADMIN_SUCCESS',
    emails
  } as const);

const addTeamAdminFailure = (statusCode: number, message: string) =>
  ({
    type: 'ADD_TEAM_ADMIN_FAILURE',
    statusCode,
    message
  } as const);

export function addTeamAdmin(emailsArg: string[] | string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    let emails: string[];
    if (Array.isArray(emailsArg)) {
      emails = emailsArg;
    } else {
      emails = [emailsArg];
    }

    dispatch(addTeamAdminAction(emails));

    return organizationTeamAddAdministrator(emails)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(addTeamAdminSuccess(emails));
          return { success: true } as const;
        }
        dispatch(addTeamAdminFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(addTeamAdminFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Remove administrator from a team
const removeTeamAdminAction = (emails: string[]) =>
  ({
    type: 'REMOVE_TEAM_ADMIN',
    team: emails
  } as const);

const removeTeamAdminSuccess = (emails: string[]) =>
  ({
    type: 'REMOVE_TEAM_ADMIN_SUCCESS',
    emails
  } as const);

const removeTeamAdminFailure = (statusCode: number, message: string) =>
  ({
    type: 'REMOVE_TEAM_ADMIN_FAILURE',
    statusCode,
    message
  } as const);

export function removeTeamAdmin(emailsArg: string | string[]) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    let emails: string[];
    if (Array.isArray(emailsArg)) {
      emails = emailsArg;
    } else {
      emails = [emailsArg];
    }

    dispatch(removeTeamAdminAction(emails));

    return organizationTeamRemoveAdministrator(emails)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(removeTeamAdminSuccess(emails));
          return { success: true } as const;
        }
        dispatch(removeTeamAdminFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(removeTeamAdminFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Leave a team
const leaveTeamAction = () =>
  ({
    type: 'LEAVE_TEAM'
  } as const);

const leaveTeamSuccess = () =>
  ({
    type: 'LEAVE_TEAM_SUCCESS'
  } as const);

const leaveTeamFailure = (statusCode: number, message: string) =>
  ({
    type: 'LEAVE_TEAM_FAILURE',
    statusCode,
    message
  } as const);

export function leaveTeam() {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(leaveTeamAction());

    return organizationTeamLeave()
      .then(e => {
        if (e.statusCode === 200 && e.token) {
          dispatch(leaveTeamSuccess());
          // Store token in browser storage
          sessionStorage.setItem('id_token', e.token);
          // Set token for API wrapper
          setAuthToken(e.token);
          return { success: true, token: e.token } as const;
        }
        dispatch(leaveTeamFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(leaveTeamFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Delete a team
const deleteTeamAction = () =>
  ({
    type: 'DELETE_TEAM'
  } as const);

const deleteTeamSuccess = () =>
  ({
    type: 'DELETE_TEAM_SUCCESS'
  } as const);

const deleteTeamFailure = (statusCode: number, message: string) =>
  ({
    type: 'DELETE_TEAM_FAILURE',
    statusCode,
    message
  } as const);

export function deleteTeam() {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(deleteTeamAction());

    return organizationTeamDelete()
      .then(e => {
        if (e.statusCode === 200 && e.token) {
          dispatch(deleteTeamSuccess());
          // Store token in browser storage
          sessionStorage.setItem('id_token', e.token);
          // Set token for API wrapper
          setAuthToken(e.token);
          return { success: true, token: e.token } as const;
        }
        dispatch(deleteTeamFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(deleteTeamFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Get team resource info
const getTeamResourceCountAction = (teamId?: string) =>
  ({
    type: 'GET_TEAM_RESOURCE_COUNT',
    team: teamId
  } as const);

const getTeamResourceCountSuccess = (teamId: string | undefined, resources: Resources) =>
  ({
    type: 'GET_TEAM_RESOURCE_COUNT_SUCCESS',
    teamId,
    resources
  } as const);

const getTeamResourceCountFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_TEAM_RESOURCE_COUNT_FAILURE',
    statusCode,
    message
  } as const);

export function getTeamResourceCount(teamId?: string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getTeamResourceCountAction(teamId));

    return organizationTeamResourceCount()
      .then(e => {
        if (e.statusCode === 200 && e.resources) {
          dispatch(getTeamResourceCountSuccess(teamId, e.resources));
          return { success: true } as const;
        }
        dispatch(getTeamResourceCountFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getTeamResourceCountFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Transfer team resources
const transferTeamResourcesAction = (organizationId: string, teamId: string) =>
  ({
    type: 'TRANSFER_TEAM_RESOURCES',
    organization: organizationId,
    team: teamId
  } as const);

const transferTeamResourcesSuccess = (organizationId: string, teamId: string, resources: Resources) =>
  ({
    type: 'TRANSFER_TEAM_RESOURCES_SUCCESS',
    organizationId,
    teamId,
    resources
  } as const);

const transferTeamResourcesFailure = (statusCode: number, message: string) =>
  ({
    type: 'TRANSFER_TEAM_RESOURCES_FAILURE',
    statusCode,
    message
  } as const);

export function transferTeamResources(organizationId: string, teamId: string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(transferTeamResourcesAction(organizationId, teamId));

    return organizationTeamResourceTransfer(organizationId, teamId)
      .then(e => {
        if (e.statusCode === 200 && e.resources) {
          dispatch(transferTeamResourcesSuccess(organizationId, teamId, e.resources));
          return { success: true } as const;
        }
        dispatch(transferTeamResourcesFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(transferTeamResourcesFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Activate software plan with key
const activatePlanAction = (activationKey: string) =>
  ({
    type: 'ACTIVATE_PLAN',
    activationKey
  } as const);

const activatePlanSuccess = () =>
  ({
    type: 'ACTIVATE_PLAN_SUCCESS'
  } as const);

const activatePlanFailure = (statusCode: number, message: string) =>
  ({
    type: 'ACTIVATE_PLAN_FAILURE',
    statusCode,
    message
  } as const);

export function activatePlan(activationKey: string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(activatePlanAction(activationKey));

    return organizationPlanActivate(activationKey)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(activatePlanSuccess());
          return { success: true } as const;
        }
        dispatch(activatePlanFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(activatePlanFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Activate software plan with key
const transferSubscriptionAction = (organizationId: string) =>
  ({
    type: 'TRANSFER_SUBSCRIPTION',
    organizationId
  } as const);

const transferSubscriptionSuccess = () =>
  ({
    type: 'TRANSFER_SUBSCRIPTION_SUCCESS'
  } as const);

const transferSubscriptionFailure = (statusCode: number, message: string) =>
  ({
    type: 'TRANSFER_SUBSCRIPTION_FAILURE',
    statusCode,
    message
  } as const);

export function transferSubscription(organizationId: string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(transferSubscriptionAction(organizationId));

    return organizationSubscriptionTransfer(organizationId)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(transferSubscriptionSuccess());
          return { success: true } as const;
        }
        dispatch(transferSubscriptionFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(transferSubscriptionFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}
