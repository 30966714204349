import { verifyAndRelayRequest, verifyAndRelayResourcesRequest } from '../utils/index';

// export async function wellplateCreate(access, name, manufacturer, modelNumber, wellplateData, printerData) {
//   return verifyAndRelayRequest({
//     route: '/wellplate/create',
//     method: 'POST',
//     protected: true,
//     data: {
//       access, name, manufacturer, modelNumber, wellplateData, printerData,
//     },
//   });
// }

// export async function wellplateRead(filter, options = {}) {
//   return verifyAndRelayRequest({
//     route: '/wellplate/read',
//     method: 'POST',
//     data: {
//       filter,
//       options,
//     },
//     protected: true,
//   });
// }

export async function wellplateUpdate(filter, update) {
  return verifyAndRelayRequest({
    route: '/wellplate/update',
    method: 'PUT',
    protected: true,
    data: {
      filter,
      update
    }
  });
}

export async function wellplateDelete(filter) {
  return verifyAndRelayRequest({
    route: '/wellplate/delete',
    method: 'DELETE',
    protected: true,
    data: { filter }
  });
}

// module.exports = {
//   wellplateCreate,
//   wellplateRead,
//   wellplateUpdate,
//   wellplateDelete,
// };

export async function wellplateCreate(access, name, manufacturer, modelNumber, wellplateData, printerData) {
  // access is unused -> will be set to PRIVATE
  // use wellplateChangeAccess to change access type if required

  return verifyAndRelayResourcesRequest({
    route: '/wellplate-create',
    method: 'POST',
    protected: true,
    data: {
      wellplate: {
        name,
        manufacturer,
        modelNumber,
        wellplateData,
        printerData
      }
    }
  });
}

export async function wellplateRead(filter, options = {}) {
  // transform _id string to array of strings
  if (typeof filter._id === 'string' || filter._id instanceof String) {
    filter._id = [filter._id];
  }

  if (filter.printerData !== null && filter.printerData !== undefined) {
    if (filter.printerData.supportedPrinters != null && Array.isArray(filter.printerData.supportedPrinters) === false) {
      filter.printerData.supportedPrinters = [filter.printerData.supportedPrinters];
    }
  }

  // transform access string to array of strings
  if (typeof filter.access === 'string' || filter.access instanceof String) {
    filter.access = [filter.access];
  }

  return verifyAndRelayResourcesRequest({
    route: '/wellplate-read',
    method: 'POST',
    data: {
      filter,
      options
    },
    protected: true
  });
}
