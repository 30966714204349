/* jslint browser: true */

import Immutable from 'seamless-immutable';
import { Organization, Resources } from '../../../apis/allevi-api-wrapper';

interface OrganizationState {
  readonly status: 'idle' | 'fetching';
  readonly organizations: readonly Organization[];
  readonly resources: Readonly<Record<string, Resources>>;
}

const initialState = Immutable<OrganizationState>({
  status: 'idle',
  organizations: [],
  resources: {}
});

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_ORGANIZATION':
      return state.set('status', 'fetching');

    case 'GET_ORGANIZATION_SUCCESS':
      if (!action.organization) {
        return state;
      }

      state = state.set('status', 'idle');
      state = state.set('organizations', action.organization);

      return state;

    case 'GET_ORGANIZATION_FAILURE':
      return state.set('status', 'idle');

    case 'GET_TEAM_RESOURCE_COUNT_SUCCESS':
      if (!action.teamId || !action.resources) {
        return state;
      }

      return state.set('resources', { [action.teamId]: action.resources });

    default:
      return state;
  }
};
