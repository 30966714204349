import React from 'react';
import NotFound from '../../pages/NotFound';
import { useUserState } from '../../state/redux/hooks';
import { PrivateRoute } from './PrivateRoute';

interface AdminRouteProps {
  path: string;
  component: React.ComponentType;
}

export const AdminRoute = (props: AdminRouteProps) => {
  const { access } = useUserState();

  if (access === 'ADMINISTRATOR') {
    return <PrivateRoute path={props.path} component={props.component} />;
  }

  if (sessionStorage.getItem('id_token') && access === '') {
    return <div />;
  }

  return <PrivateRoute component={NotFound} />;
};
