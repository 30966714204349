import { useSelector as useSelector_ } from 'react-redux';
import { RootState } from '../reducers';

export function useSelector<TSelected = unknown>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
  return useSelector_<RootState, TSelected>(selector, equalityFn);
}

export function useFileState() {
  return useSelector(state => state.file);
}
export function useUserState() {
  return useSelector(state => state.user);
}
export function useOrganizationState() {
  return useSelector(state => state.organization);
}
export function useDeviceState() {
  return useSelector(state => state.device);
}
export function usePrinterState() {
  return useSelector(state => state.printer);
}
export function useWellplateState() {
  return useSelector(state => state.wellplate);
}
export function useCommState() {
  return useSelector(state => state.comm);
}
export function useProjectState() {
  return useSelector(state => state.project);
}
export function useMaterialState() {
  return useSelector(state => state.material);
}
export function useNucState() {
  return useSelector(state => state.nuc);
}
export function useAdapterState() {
  return useSelector(state => state.adapter);
}
