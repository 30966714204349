import Color from 'color';

//
// Px to rem conversion
//
export function px(p: number) {
  return `${Math.round(p) / 16}rem`;
}

//
// Fade a color
//
export function fade(color: string, amount: number) {
  return Color(color).fade(amount).string();
}

//
// Lighten a color
//
export function lighten(color: string, amount: number) {
  return Color(color).lighten(amount).hex();
}

//
// Whiten a color
//
export function whiten(color: string, amount: number) {
  return Color(color).whiten(amount).hex();
}
