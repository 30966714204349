import { verifyAndRelayRequest } from '../utils/index';

export async function printQueue(data) {
  return verifyAndRelayRequest({
    route: '/print/queue',
    method: 'POST',
    data: {
      data
    },
    protected: true
  });
}
