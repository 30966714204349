import { verifyAndRelayRequest } from '../utils/index';

export async function uploadAlleviAdapterLogs(printer, logs) {
  return verifyAndRelayRequest({
    route: '/allevi-adapter/logs',
    method: 'POST',
    protected: true,
    data: {
      printer,
      logs
    }
  });
}
