import React from 'react';
import styled from 'styled-components';

import { AppBar, Toolbar } from '@material-ui/core';

// Icons
import Icon from '@mdi/react';
import { mdiCircleSlice8 } from '@mdi/js';

import { px } from '../../util/style';
import { colors, din, pulsePartial } from '../../styles/variables';

//
// Header
//
const StyledHeader = styled(AppBar)`
  box-shadow: none;
`;
interface StyledToolbar {
  onprem?: boolean;
}

const StyledToolbar = styled(Toolbar)<StyledToolbar>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.alleviRed};
  padding: ${px(16)} ${px(48)};

  & p {
    display: flex;
    flex-flow: row;
    font-family: ${din};
    font-size: ${px(16)};
    font-weight: 400;
    margin: 0;
    padding: 0;

    & svg {
      margin-right: ${px(12)};
    }
  }

  ${props =>
    props.onprem &&
    `
    padding: ${px(12)} ${px(48)};
    min-height: ${px(40)};
    background: #54AEB9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #54AEB9, #e1f6f1);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #54AEB9, #e1f6f1); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-top: 3px solid rgba(0, 0, 0, 0.05);
    border-bottom: 3px solid rgba(0, 0, 0, 0.05);

    & p {
      font-size: ${px(18)};
      font-weight: 300;
    }

    & b {
      font-weight: 600;
      margin-left: ${px(4)};
    }

    ${pulsePartial};

    & svg {
      animation: 2s infinite alternate pulsePartial;
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
  `}
`;

interface onPremBar {
  header?: boolean;
}

const OnPremBar = (props: onPremBar) => {
  const onPrem = window.hostEnvironment && window.hostEnvironment === 'ON_PREM';

  if (props.header) {
    return (
      <StyledHeader position="static">
        {onPrem && (
          <StyledToolbar onprem>
            <p>
              <Icon path={mdiCircleSlice8} size={px(24)} color={colors.white} />
              Connected to:
              <b>On-Premises Gateway</b>
            </p>
          </StyledToolbar>
        )}
      </StyledHeader>
    );
  }

  return (
    <>
      {onPrem && (
        <StyledToolbar onprem>
          <p>
            <Icon path={mdiCircleSlice8} size={px(24)} color={colors.white} />
            Connected to:
            <b>On-Premises Gateway</b>
          </p>
        </StyledToolbar>
      )}
    </>
  );
};

export default OnPremBar;
