// import Immutable from 'seamless-immutable';
import produce, { castDraft, Draft } from 'immer';
import { Reducer } from 'redux';
import { Experiment, ExperimentFile, ProjectStep } from '../../../apis/allevi-api-wrapper';

export interface ProjectState {
  readonly status: string;
  readonly error: string;
  readonly searchString: string;
  readonly sortBy: 'Recently changed' | 'Newest' | 'Name';
  readonly page: number;
  readonly project: Experiment;
  readonly projects: readonly Experiment[];
  readonly step: ProjectStep | -1 | 0 | 1 | 2 | 3 | 4;
  readonly files: ExperimentFile[];
}

const initialState: ProjectState = {
  status: 'idle',
  error: '',
  searchString: '',
  sortBy: 'Recently changed',
  page: 0,
  project: {
    _id: undefined as TSFixMe,
    name: '',
    printer: {
      modelNumber: 1
    },
    updatedAt: ''
  } as Partial<Experiment> as TSFixMe,
  projects: [],
  step: -1,
  files: []
};

export default produce<(state: Draft<ProjectState>, action: any) => Draft<ProjectState>>((state, action) => {
  switch (action.type) {
    case 'RESET_PROJECT':
      state.project = castDraft(initialState.project);
      return state;

    case 'SET_PROJECT':
      state.project = action.project;
      return state;

    case 'SET_PROJECT_STEP':
      state.step = action.step;
      return state;

    case 'SET_PROJECT_SORT':
      state.sortBy = action.sortBy;
      return state;

    case 'SET_PROJECT_SEARCH_STRING':
      state.searchString = action.searchString;
      return state;

    case 'SET_PROJECT_PAGE':
      state.page = action.page;
      return state;

    case 'READ_PROJECT':
      state.status = 'fetching';
      return state;

    case 'READ_PROJECT_SUCCESS':
      state.status = 'idle';
      state.project = action.project;
      return state;

    case 'UPDATE_PROJECT_SUCCESS':
      state.project = action.project;
      return state;

    case 'GET_ALL_PROJECTS':
      state.status = 'fetching';
      return state;

    case 'GET_ALL_PROJECTS_SUCCESS':
      state.status = 'idle';
      state.projects = action.projects;
      return state;

    case 'GET_PROJECT_TEMPLATES':
      state.status = 'fetching';
      return state;

    case 'GET_PROJECT_TEMPLATES_SUCCESS':
      state.status = 'idle';
      state.projects = action.projects;
      return state;

    case 'READ_PROJECT_FAILURE':
      state.error = 'Project failed to load. Ensure it was not deleted and try again';
      return state;

    default:
      return state;
  }
}, initialState) as Reducer<ProjectState, any>;
