import Immutable from 'seamless-immutable';
import { Material } from '../../../apis/allevi-api-wrapper';

interface MaterialState {
  readonly status: 'idle' | 'fetching';
  readonly materials: readonly Material[];
}

const initialState = Immutable<MaterialState>({
  status: 'idle',
  materials: []
});

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_ALL_MATERIALS':
      return state.set('status', 'fetching');

    case 'GET_ALL_MATERIALS_SUCCESS':
    case 'GET_ALL_MATERIALS_BY_PRINTER_SUCCESS':
      state = state.set('status', 'idle');
      state = state.set('materials', action.materials);

      return state;

    default:
      return state;
  }
};
