import { PartialDeep } from 'type-fest';
import { Axis } from '../../../util/gcode';
import { CrossLinkingState, DeviceState, ExtruderState, PrintParams } from '../reducers/device';

//  Redux Actions
export const resetDevice = () =>
  ({
    type: 'RESET_DEVICE'
  } as const);

export const setPrinterData = (printerData: Partial<DeviceState>) =>
  ({
    type: 'SET_PRINTER_DATA',
    printerData
  } as const);

export const setStatus = (status: DeviceState['status']) =>
  ({
    type: 'SET_DEVICE_STATUS',
    status
  } as const);

export const setActiveSection = (section: DeviceState['activeSection']) =>
  ({
    type: 'SET_ACTIVE_SECTION',
    section
  } as const);

export const setConnectionStatus = (status: DeviceState['connectionStatus']) =>
  ({
    type: 'SET_CONNECTION_STATUS',
    status
  } as const);

export const setConnectionHealth = (status: DeviceState['connectionHealth']) =>
  ({
    type: 'SET_CONNECTION_HEALTH',
    status
  } as const);

export const setWorklightStatus = (status: boolean) =>
  ({
    type: 'SET_WORKLIGHT_STATUS',
    status
  } as const);

// Current Print
export const setWellsToPrint = (wells: DeviceState['wellsToPrint']) =>
  ({
    type: 'SET_WELLS_TO_PRINT',
    wells
  } as const);

export const setCurrentPrintIndex = (index: number) =>
  ({
    type: 'SET_CURRENT_PRINT_INDEX',
    index
  } as const);

export const setPrintParams = (params: PrintParams, printIndex = 0) =>
  ({
    type: 'SET_PRINT_PARAMS',
    params,
    printIndex
  } as const);

export const removePrintParams = (printIndex: number) =>
  ({
    type: 'REMOVE_PRINT_PARAMS',
    printIndex
  } as const);

// Build plate
export const setBuildPlate = (buildplate: string, wellCount: number) =>
  ({
    type: 'SET_BUILD_PLATE',
    buildplate,
    wellCount
  } as const);

// Extruders
export const setSelectedExtruder = (selectedExtruder: number) =>
  ({
    type: 'SET_SELECTED_EXTRUDER',
    selectedExtruder
  } as const);

export const setExtruderJogStep = (step: number) =>
  ({
    type: 'SET_EXTRUDER_JOG_STEP',
    step
  } as const);

export const toggleExtruderEnabled = (extruder: number) =>
  ({
    type: 'TOGGLE_EXTRUDER_ENABLED',
    extruder
  } as const);

export const setExtruderStatus = (extruder: number, status: ExtruderState['status']) =>
  ({
    type: 'SET_EXTRUDER_STATUS',
    extruder,
    status
  } as const);

export const moveExtruder = (extruder: number, axis: Axis, delta: number) =>
  ({
    type: 'MOVE_EXTRUDER',
    extruder,
    axis,
    delta
  } as const);

export const setExtruderTargetPosition = (extruder: number, position: [number, number, number]) =>
  ({
    type: 'SET_EXTRUDER_TARGET_POSITION',
    extruder,
    position
  } as const);

export const toggleExtruderTempActive = (extruder: number, override?: boolean) =>
  ({
    type: 'TOGGLE_EXTRUDER_TEMP_ACTIVE',
    extruder,
    override
  } as const);

export const setExtruderTargetTemp = (extruder: number, temp: number) =>
  ({
    type: 'SET_EXTRUDER_TARGET_TEMP',
    extruder,
    temp
  } as const);

export const toggleExtruderPressureActive = (extruder: number, override: boolean) =>
  ({
    type: 'TOGGLE_EXTRUDER_PRESSURE_ACTIVE',
    extruder,
    override
  } as const);

export const setExtruderTargetPressure = (extruder: number, pressure: number) =>
  ({
    type: 'SET_EXTRUDER_TARGET_PRESSURE',
    extruder,
    pressure
  } as const);

// Bed
export const toggleBedTempActive = (override?: boolean) =>
  ({
    type: 'TOGGLE_BED_TEMP_ACTIVE',
    override
  } as const);

export const setBedTargetTemp = (temp: number) =>
  ({
    type: 'SET_BED_TARGET_TEMP',
    temp
  } as const);

// Crosslinking
export const setCrosslinkingParams = (params: PartialDeep<CrossLinkingState>) =>
  ({
    type: 'SET_CROSSLINKING_PARAMS',
    params
  } as const);
