import { ThunkDispatch } from 'redux-thunk';
import {
  projectRead,
  projectCreate,
  projectUpdate,
  projectDelete,
  projectClone,
  Experiment,
  ExperimentWithoutLinks
} from '../../../apis/allevi-api-wrapper';
import { ProjectState } from '../reducers/project';

import { logoutOnInvalidToken } from '../util/auth';

export const resetProject = () =>
  ({
    type: 'RESET_PROJECT'
  } as const);

export const setProject = (project: Experiment) =>
  ({
    type: 'SET_PROJECT',
    project
  } as const);

export const setProjectStep = (step: ProjectState['step']) =>
  ({
    type: 'SET_PROJECT_STEP',
    step
  } as const);

export const setProjectSort = (sortBy: ProjectState['sortBy']) =>
  ({
    type: 'SET_PROJECT_SORT',
    sortBy
  } as const);

export const setProjectSearchString = (searchString: string) =>
  ({
    type: 'SET_PROJECT_SEARCH_STRING',
    searchString
  } as const);

export const setProjectPage = (page: number) =>
  ({
    type: 'SET_PROJECT_PAGE',
    page
  } as const);

// Get all projects
const getAllProjectsAction = () =>
  ({
    type: 'GET_ALL_PROJECTS'
  } as const);

const getAllProjectsSuccess = (projects: Experiment[]) =>
  ({
    type: 'GET_ALL_PROJECTS_SUCCESS',
    projects
  } as const);

const getAllProjectsFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_ALL_PROJECTS_FAILURE',
    statusCode,
    message
  } as const);

export function getAllProjects(skip = 0, limit = 1000, sort = {}) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getAllProjectsAction());

    return projectRead({ access: 'PRIVATE' }, { skip, limit, sort })
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(getAllProjectsSuccess(e.experiments ? e.experiments : []));
          return { projects: e.experiments ? e.experiments : [], success: true } as const;
        }

        dispatch(getAllProjectsFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(getAllProjectsFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Get project templates
const getProjectTemplatesAction = () =>
  ({
    type: 'GET_PROJECT_TEMPLATES'
  } as const);

const getProjectTemplatesSuccess = (projects: Experiment[]) =>
  ({
    type: 'GET_PROJECT_TEMPLATES_SUCCESS',
    projects
  } as const);

const getProjectTemplatesFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_PROJECT_TEMPLATES_FAILURE',
    statusCode,
    message
  } as const);

export function getProjectTemplates(skip = 0, limit = 1000, sort: Record<string, number> = {}) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getProjectTemplatesAction());

    return projectRead({ access: 'DEFAULT' }, { skip, limit, sort })
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(getProjectTemplatesSuccess(e.experiments ? e.experiments : []));
          return { projects: e.experiments ? e.experiments : [], success: true } as const;
        }

        dispatch(getProjectTemplatesFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(getProjectTemplatesFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Read project
const readProjectAction = (projectId: string) =>
  ({
    type: 'READ_PROJECT',
    projectId
  } as const);

const readProjectSuccess = (project: Experiment) =>
  ({
    type: 'READ_PROJECT_SUCCESS',
    project
  } as const);

const readProjectFailure = (statusCode: number, message: string) =>
  ({
    type: 'READ_PROJECT_FAILURE',
    statusCode,
    message
  } as const);

export function readProject(projectId: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(readProjectAction(projectId));

    return projectRead({ _id: projectId })
      .then(e => {
        if (e.statusCode === 200 && e.experiments) {
          dispatch(readProjectSuccess(e.experiments[0]!));
          return { project: e.experiments[0], success: true } as const;
        }

        dispatch(readProjectFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(readProjectFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Create project
const createProjectAction = (name: string, description: string, printer: { modelNumber: number }) =>
  ({
    type: 'CREATE_PROJECT',
    name,
    description,
    printer
  } as const);

const createProjectSuccess = (project: Experiment) =>
  ({
    type: 'CREATE_PROJECT_SUCCESS',
    project
  } as const);

const createProjectFailure = (statusCode: number, message: string) =>
  ({
    type: 'CREATE_PROJECT_FAILURE',
    statusCode,
    message
  } as const);

export function createProject(name: string, description: string, printer: { modelNumber: number }) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(createProjectAction(name, description, printer));

    return projectCreate('PRIVATE', name, description, printer)
      .then(e => {
        if (e.statusCode === 200 && e.experiment) {
          dispatch(createProjectSuccess(e.experiment));
          return { project: e.experiment, success: true } as const;
        }

        dispatch(createProjectFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(createProjectFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Update project
const updateProjectAction = (projectId: string, update: ExperimentWithoutLinks) =>
  ({
    type: 'UPDATE_PROJECT',
    projectId,
    update
  } as const);

const updateProjectSuccess = (project: Experiment) =>
  ({
    type: 'UPDATE_PROJECT_SUCCESS',
    project
  } as const);

const updateProjectFailure = (statusCode: number, message: string) =>
  ({
    type: 'UPDATE_PROJECT_FAILURE',
    statusCode,
    message
  } as const);

export function updateProject(projectId: string, update: ExperimentWithoutLinks) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(updateProjectAction(projectId, update));

    return projectUpdate({ _id: projectId }, update)
      .then(e => {
        if (e.statusCode === 200 && e.experiment) {
          dispatch(updateProjectSuccess(e.experiment));
          return { project: e.experiment, success: true } as const;
        }

        dispatch(updateProjectFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(updateProjectFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Delete project
const deleteProjectAction = (projectId: string) =>
  ({
    type: 'DELETE_PROJECT',
    projectId
  } as const);

const deleteProjectSuccess = () =>
  ({
    type: 'DELETE_PROJECT_SUCCESS'
  } as const);

const deleteProjectFailure = (statusCode: number, message: string) =>
  ({
    type: 'DELETE_PROJECT_FAILURE',
    statusCode,
    message
  } as const);

export function deleteProject(projectId: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(deleteProjectAction(projectId));

    return projectDelete({ _id: projectId })
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(deleteProjectSuccess());
          return { success: true } as const;
        }

        dispatch(deleteProjectFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(deleteProjectFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Clone project
const cloneProjectAction = (projectId: string, targetPrinter: number) =>
  ({
    type: 'CLONE_PROJECT',
    projectId,
    targetPrinter
  } as const);

const cloneProjectSuccess = (newProjectId: string) =>
  ({
    type: 'CLONE_PROJECT_SUCCESS',
    newProjectId
  } as const);

const cloneProjectFailure = (statusCode: number, message: string) =>
  ({
    type: 'CLONE_PROJECT_FAILURE',
    statusCode,
    message
  } as const);

export function cloneProject(projectId: string, targetPrinter: number) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(cloneProjectAction(projectId, targetPrinter));

    return projectClone(projectId, targetPrinter)
      .then(e => {
        if (e.statusCode === 200 && e.experiments) {
          dispatch(cloneProjectSuccess(e.experiments[0]!._id));
          return { newProjectId: e.experiments[0]?._id, success: true } as const;
        }

        dispatch(cloneProjectFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(cloneProjectFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}
