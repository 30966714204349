import { verifyAndRelayRequest } from '../utils/index';

export async function slicerSlice(files, slicingParameters, printerParameters) {
  return verifyAndRelayRequest({
    route: '/slicer/slice',
    method: 'POST',
    data: {
      files,
      slicingParameters,
      printerParameters
    },
    protected: true
  });
}
