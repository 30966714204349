import axios from 'axios';

import { ThunkDispatch } from 'redux-thunk';
import {
  fileRead,
  fileCreate,
  fileUpload,
  fileDelete,
  FileCreateResponse,
  File,
  Filter
} from '../../../apis/allevi-api-wrapper';
import { FileState } from '../reducers/file';

// Set print file status
export const setPrintFileStatus = (status: FileState['status']) =>
  ({
    type: 'SET_PRINT_FILE_STATUS',
    status
  } as const);

export const setDisplayedFiles = (files: FileState['displayedFiles']) =>
  ({
    type: 'SET_DISPLAYED_FILES',
    files
  } as const);

export const setFilePage = (page: number) =>
  ({
    type: 'SET_FILE_PAGE',
    page
  } as const);

// Get filtered files
const getFilteredFilesAction = () =>
  ({
    type: 'GET_FILTERED_FILES'
  } as const);

const getFilteredFilesSuccess = (files: File[]) =>
  ({
    type: 'GET_FILTERED_FILES_SUCCESS',
    files
  } as const);

const getFilteredFilesFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_FILTERED_FILES_FAILURE',
    statusCode,
    message
  } as const);

export function getFilteredFiles(filter: Filter, skip = 0, limit = 1000, sort: Record<string, number> = {}) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getFilteredFilesAction());

    return fileRead(
      {
        access: ['PUBLIC', 'PRIVATE', 'DEFAULT'],
        ...filter
      },
      true,
      { skip, limit, sort }
    )
      .then(e => {
        if (e.statusCode === 200 && e.files) {
          dispatch(getFilteredFilesSuccess(e.files));
          return { files: e.files, success: true } as const;
        }
        dispatch(getFilteredFilesFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getFilteredFilesFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Get all files
const getAllFilesAction = () =>
  ({
    type: 'GET_ALL_FILES'
  } as const);

const getAllFilesSuccess = (files: File[]) =>
  ({
    type: 'GET_ALL_FILES_SUCCESS',
    files
  } as const);

const getAllFilesFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_ALL_FILES_FAILURE',
    statusCode,
    message
  } as const);

export function getAllFiles(skip = 0, limit = 1000, sort: Record<string, number> = {}) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getAllFilesAction());

    return fileRead({ access: ['PUBLIC', 'PRIVATE', 'DEFAULT'] }, true, { skip, limit, sort })
      .then(e => {
        if (e.statusCode === 200 && e.files) {
          dispatch(getAllFilesSuccess(e.files));
          return { files: e.uploadFileSuccess, success: true } as const;
        }
        dispatch(getAllFilesFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getAllFilesFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Upload a file
const uploadFileAction = (fileName: string, fileType: string, file: File) =>
  ({
    type: 'UPLOAD_FILE',
    fileName,
    fileType,
    file
  } as const);

const uploadFileSuccess = (file: any) =>
  ({
    type: 'UPLOAD_FILE_SUCCESS',
    file
  } as const);

const uploadFileFailure = (statusCode: number, message: string) =>
  ({
    type: 'UPLOAD_FILE_FAILURE',
    statusCode,
    message
  } as const);

export function uploadFile(fileName: string, fileType: string, file: File) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(uploadFileAction(fileName, fileType, file));

    let createResponse: FileCreateResponse | undefined = undefined;
    try {
      createResponse = await fileCreate('PRIVATE', fileName, fileType);
      if (createResponse.statusCode === 200) {
        const uploadResponse = await fileUpload(createResponse.file.url, createResponse.file.fsType, file);
        dispatch(uploadFileSuccess(uploadResponse));
        return { success: true, file: createResponse.file } as const;
      } else {
        dispatch(uploadFileFailure(500, 'Error uploading file'));
        return { success: false } as const;
      }
    } catch (error) {
      dispatch(uploadFileFailure(500, 'Error uploading file'));

      // Delete file from DB if upload fails
      if (createResponse?.data.fileData && createResponse?.data.fileData.file) {
        dispatch(deleteFile(createResponse.data.fileData.file._id));
      }

      return { success: false } as const;
    }
  };
}

// Delete a file
const deleteFileAction = (fileId: string) =>
  ({
    type: 'DELETE_FILE',
    fileId
  } as const);

const deleteFileSuccess = (fileId: string) =>
  ({
    type: 'DELETE_FILE_SUCCESS',
    fileId
  } as const);

const deleteFileFailure = (statusCode: number, message: string) =>
  ({
    type: 'DELETE_FILE_FAILURE',
    statusCode,
    message
  } as const);

export function deleteFile(fileId: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(deleteFileAction(fileId));

    return fileDelete({ _id: fileId })
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(deleteFileSuccess(fileId));
          return { success: true } as const;
        }
        dispatch(deleteFileFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(deleteFileFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Download a file
const downloadFileAction = (fileId: string) =>
  ({
    type: 'DOWNLOAD_FILE',
    fileId
  } as const);

const downloadFileSuccess = () =>
  ({
    type: 'DOWNLOAD_FILE_SUCCESS'
  } as const);

const downloadFileFailure = (message: string) =>
  ({
    type: 'DOWNLOAD_FILE_FAILURE',
    message
  } as const);

export function downloadFile(fileId: string, fsType: string) {
  const onPrem = window.hostEnvironment && window.hostEnvironment === 'ON_PREM';

  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(downloadFileAction(fileId));

    try {
      const apiResponse = await fileRead({ _id: fileId }, true);

      if (apiResponse.statusCode !== 200) {
        dispatch(downloadFileFailure('File download link generation failed'));
        return { success: false } as const;
      }

      let fileReq = {};
      if (onPrem) {
        fileReq = {
          method: 'GET',
          responseType: 'blob',
          data: {}
        };
      } else {
        fileReq = {
          method: 'GET',
          headers: {
            'Content-Type': fsType
          },
          responseType: 'blob',
          data: {}
        };
      }

      const fileResponse = await axios(apiResponse.files![0].url, fileReq);

      if (fileResponse.status !== 200) {
        dispatch(downloadFileFailure('File download failed'));
        return { success: false } as const;
      }

      dispatch(downloadFileSuccess());
      return { success: true, data: fileResponse.data } as const;
    } catch (e) {
      dispatch(downloadFileFailure(`File download failed: ${e}`));
      return { success: false } as const;
    }
  };
}

// Generate a link to a file
const generateFileLinkAction = (fileId: string) =>
  ({
    type: 'GENERATE_FILE_LINK',
    fileId
  } as const);

const generateFileLinkSuccess = (url: string, checksum: string) =>
  ({
    type: 'GENERATE_FILE_LINK_SUCCESS',
    url,
    checksum
  } as const);

const generateFileLinkFailure = (statusCode: number, message: string) =>
  ({
    type: 'GENERATE_FILE_FAILURE',
    statusCode,
    message
  } as const);

export function generateFileLink(fileId: string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(generateFileLinkAction(fileId));

    try {
      const apiResponse = await fileRead({ _id: fileId }, true);

      if (apiResponse.statusCode !== 200) {
        dispatch(generateFileLinkFailure(apiResponse.statusCode, 'File download link generation failed'));
        return { success: false } as const;
      }

      dispatch(generateFileLinkSuccess(apiResponse.files![0].url, apiResponse.files![0].checksum));
      return { success: true, url: apiResponse.files![0].url, checksum: apiResponse.files![0].checksum } as const;
    } catch (e) {
      dispatch(generateFileLinkFailure(-1, `File download link generation failed: ${e}`));
      return { success: false } as const;
    }
  };
}
