import Immutable from 'seamless-immutable';
import { File } from '../../../apis/allevi-api-wrapper';
import { assertActionType } from '../util/assertActionType';
import { setDisplayedFiles, setFilePage, setPrintFileStatus } from '../actions/fileActions';

export interface FileState {
  readonly status: 'error' | 'idle' | 'uploading';
  readonly files: readonly File[];
  readonly displayedFiles: 'user' | 'allevi';
  readonly page: number;
  readonly loading: boolean;
}

const initialState = Immutable<FileState>({
  status: 'idle',
  displayedFiles: 'user',
  page: 0,
  loading: false,
  files: []
});

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_PRINT_FILENAME':
      if (action.filename === undefined) return state;

      return state.setIn(['filename'], action.filename);

    case 'SET_PRINT_FILE_STATUS':
      assertActionType(action, setPrintFileStatus);
      if (action.status === undefined) return state;

      return state.setIn(['status'], action.status);

    case 'SET_DISPLAYED_FILES':
      assertActionType(action, setDisplayedFiles);
      if (action.files === undefined) return state;

      return state.setIn(['displayedFiles'], action.files);

    case 'SET_FILE_PAGE':
      assertActionType(action, setFilePage);
      if (action.page === undefined) return state;

      return state.setIn(['page'], action.page);

    case 'UPLOAD_FILE':
      return state.setIn(['status'], 'uploading');

    case 'UPLOAD_FILE_SUCCESS':
      return state.setIn(['status'], 'idle');

    case 'UPLOAD_FILE_FAILURE':
      return state.setIn(['status'], 'error');

    case 'DELETE_FILE_SUCCESS':
      if (action.fileId === undefined) return state;

      const files = state.files.filter(f => f._id !== action.fileId);
      return state.setIn(['files'], files);

    case 'GET_ALL_FILES':
    case 'GET_FILTERED_FILES':
      return state.set('loading', true);

    case 'GET_ALL_FILES_FAILURE':
    case 'GET_FILTERED_FILES_FAILURE':
      return state.set('loading', false);

    case 'GET_ALL_FILES_SUCCESS':
    case 'GET_FILTERED_FILES_SUCCESS':
      if (action.files === undefined) return state;

      state = state.set('loading', false);

      return state.setIn(['files'], action.files);

    default:
      return state;
  }
};
