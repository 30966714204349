import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

export default () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  //@ts-expect-error
  const store = createStore(rootReducer, composeEnhancers(/* initialState,  */ applyMiddleware(thunk)));

  if (process.env.NODE_ENV !== 'production') {
    //@ts-expect-error
    if (module.hot) {
      //@ts-expect-error
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
};
