import { v4 as uuidv4 } from 'uuid';

import { Matrix4, Euler } from 'three';

import { ThunkDispatch } from 'redux-thunk';
import { printQueue, slicerSlice, printerSendCommand, ExperimentFile } from '../../../apis/allevi-api-wrapper';
import {
  setAuthToken,
  setFirebaseConfig,
  setCouchDBConfig,
  refreshToken,
  initialize,
  getLastPrinterState,
  connectToPrinter,
  disconnectFromPrinter,
  connectNotificationListener,
  disconnectNotificationListener,
  connectTaskListener,
  disconnectTaskListener,
  CommActions
} from '../../../apis/allevi-comm-wrapper';
import gcode, { Axis } from '../../../util/gcode';
import { CommState } from '../reducers/comm';
import { RootState } from '../reducers';
import { sendAlleviAdapterCommand } from '../../../apis/allevi-adapter-wrapper';

const allCommActions: CommActions = {
  // DB init
  onConnectToDB: 'COMM_CONNECT',
  onDisconnectFromDB: 'COMM_DISCONNECT',

  // Printer get last state
  onGetLastPrinterState: 'PRINTER_GET_LAST_STATE',
  onGetLastPrinterStateSuccess: 'PRINTER_GET_LAST_STATE_SUCCESS',
  onGetLastPrinterStateFailure: 'PRINTER_GET_LAST_STATE_FAILURE',

  // Printer comm
  onConnectToPrinter: 'PRINTER_CONNECT',
  onDisconnectFromPrinter: 'PRINTER_DISCONNECT',
  onMessage: 'PRINTER_MESSAGE',

  // Notifications
  onConnectNotificationListener: 'NOTIFICATIONS_CONNECT',
  onDisconnectNotificationListener: 'NOTIFICATIONS_DISCONNECT',
  onSlicerNotification: 'NOTIFICATIONS_SLICER_MESSAGE',

  // Task listener
  onConnectTaskListener: 'TASK_CONNECT',
  onDisconnectTaskListener: 'TASK_DISCONNECT',
  onTaskNotification: 'TASK_MESSAGE'
};

//  Set firebase config
const commSetConfigAction = (config: TSFixMe) =>
  ({
    type: 'COMM_SET_CONFIG',
    config
  } as const);

export const commSetConfig = (config: TSFixMe) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(commSetConfigAction(config));
  if (window.hostEnvironment && window.hostEnvironment === 'ON_PREM') {
    return setCouchDBConfig(config);
  }

  return setFirebaseConfig(config);
};

// Set last print sent
export const setLastPrint = (uuid: string) =>
  ({
    type: 'SET_LAST_PRINT',
    uuid
  } as const);

//  Force refresh auth token
const commRefreshTokenAction = () =>
  ({
    type: 'COMM_REFRESH_TOKEN'
  } as const);

export const commRefreshToken = (token: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(commRefreshTokenAction());
  if (token) {
    return setAuthToken(token).then(() => refreshToken());
  }
  return refreshToken();
};

//  Initialize comm
const commInitializeAction = () =>
  ({
    type: 'COMM_INITIALIZE'
  } as const);

export const commInitialize = (token: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(commInitializeAction());
  return setAuthToken(token).then(() => initialize(dispatch, allCommActions));
};

// Get last printer state
const printerGetLastStateAction = (printer: string) =>
  ({
    type: 'PRINTER_GET_LAST_STATE',
    printer
  } as const);

export const printerGetLastState = (printer: string) => {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(printerGetLastStateAction(printer));
    return getLastPrinterState(dispatch, allCommActions, printer);
  };
};

// Connect to printer
const printerConnectAction = (printer: string) =>
  ({
    type: 'PRINTER_CONNECT',
    printer
  } as const);

export const printerConnect = (printer: string) => {
  sessionStorage.setItem('connected_printer', printer);

  return (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const printerConnection = getState().adapter.serialNumber === printer ? 'useAlleviClient' : 'useWifi';
    dispatch(printerConnectAction(printer));
    return connectToPrinter(dispatch, printerConnection, allCommActions, printer);
  };
};

// Disconnect from printer
const printerDisconnectAction = (printer: string) =>
  ({
    type: 'PRINTER_DISCONNECT',
    printer
  } as const);

export const printerDisconnect = (printer: string) => {
  sessionStorage.removeItem('connected_printer');

  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(printerDisconnectAction(printer));
    return disconnectFromPrinter(dispatch, allCommActions, printer);
  };
};

// Connect notifications listener for user
const notificationsConnectAction = (userId: string) =>
  ({
    type: 'NOTIFICATIONS_INIT',
    userId
  } as const);

export const notificationsConnect = (userId: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(notificationsConnectAction(userId));
  return connectNotificationListener(dispatch, allCommActions, userId);
};

// Disconnect notifications listener for user
const notificationsDisconnectAction = (userId: string) =>
  ({
    type: 'NOTIFICATIONS_DISABLE',
    userId
  } as const);

export const notificationsDisconnect = (userId: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(notificationsDisconnectAction(userId));
  return disconnectNotificationListener(dispatch, allCommActions, userId);
};

// Connect notifications listener for task
const taskListenerConnectAction = (userId: string, teamId: string, taskId: string) =>
  ({
    type: 'TASK_LISTENER_CONNECT',
    userId,
    teamId,
    taskId
  } as const);

export const taskListenerConnect =
  (userId: string, teamId: string, taskId: string) => (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(taskListenerConnectAction(userId, teamId, taskId));
    return connectTaskListener(dispatch, allCommActions, userId, teamId, taskId);
  };

// Disconnect notifications listener for task
const taskListenerDisconnectAction = (userId: string, teamId: string, taskId: string) =>
  ({
    type: 'TASK_LISTENER_DISCONNECT',
    userId,
    teamId,
    taskId
  } as const);

export const taskListenerDisconnect =
  (userId: string, teamId: string, taskId: string) => (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(taskListenerDisconnectAction(userId, teamId, taskId));
    return disconnectTaskListener(dispatch, allCommActions, userId, teamId, taskId);
  };

//
// Queue basic prints
//
const queueBasicPrintAction = (files: readonly ExperimentFile[], modelNumber: number) =>
  ({
    type: 'QUEUE_BASIC_PRINT',
    files,
    modelNumber
  } as const);

const queueBasicPrintSuccess = (status: boolean, taskId: string) =>
  ({
    type: 'QUEUE_BASIC_PRINT_SUCCESS',
    status,
    taskId
  } as const);

const queueBasicPrintFailure = (statusCode: number, message: string) =>
  ({
    type: 'QUEUE_BASIC_PRINT_FAILURE',
    statusCode,
    message
  } as const);

export const queueBasicPrint =
  (
    files: readonly ExperimentFile[],
    modelNumber: number,
    wellCount: number,
    serialNumber?: string,
    generateCommandsOnly = false
  ) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(queueBasicPrintAction(files, modelNumber));

    return printQueue({
      type: 'BASIC_PRINT_JOB',
      files,
      modelNumber,
      wellCount,
      serialNumber,
      generateCommandsOnly
    })
      .then(e => {
        if (e.statusCode === 200 && e.status === true) {
          dispatch(queueBasicPrintSuccess(e.status, e.taskId!));
          return {
            success: true,
            taskId: e.taskId!
          } as const;
        }
        dispatch(queueBasicPrintFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(queueBasicPrintFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };

//
// Queue project prints
//
const queueProjectPrintAction = (projectId: string, serialNumber?: string) =>
  ({
    type: 'QUEUE_PROJECT_PRINT',
    projectId,
    serialNumber
  } as const);

const queueProjectPrintSuccess = (status: boolean, taskId: string) =>
  ({
    type: 'QUEUE_PROJECT_PRINT_SUCCESS',
    status,
    taskId
  } as const);

const queueProjectPrintFailure = (statusCode: number, message: string) =>
  ({
    type: 'QUEUE_PROJECT_PRINT_FAILURE',
    statusCode,
    message
  } as const);

export const queueProjectPrint =
  (projectId: string, serialNumber?: string, generateCommandsOnly: boolean = false) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(queueProjectPrintAction(projectId, serialNumber));

    return printQueue({
      type: 'PROJECT_PRINT_JOB',
      projectId,
      serialNumber,
      generateCommandsOnly
    })
      .then(e => {
        if (e.statusCode === 200 && e.status === true) {
          dispatch(queueProjectPrintSuccess(e.status, e.taskId!));
          return {
            success: true,
            taskId: e.taskId!
          } as const;
        }
        dispatch(queueProjectPrintFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(queueProjectPrintFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };

//
// Slicer interface
//
export const slicerReset = () =>
  ({
    type: 'RESET_SLICER_STATUS'
  } as const);

const slicerSubmitMultipleWithParamsAction = (
  prints: readonly ExperimentFile[],
  slicingParameters: { layerHeight: number },
  printerParameters: { modelNumber: number },
  queueFor: string,
  uuid: string
) =>
  ({
    type: 'SLICER_SUBMIT_MULTIPLE_WITH_PARAMS',
    prints,
    slicingParameters,
    printerParameters,
    queueFor,
    uuid
  } as const);

const slicerSubmitMultipleWithParamsSuccess = (status: boolean, queueFor: string, uuid: string) =>
  ({
    type: 'SLICER_SUBMIT_MULTIPLE_WITH_PARAMS_SUCCESS',
    status,
    queueFor,
    uuid
  } as const);

const slicerSubmitMultipleWithParamsFailure = (statusCode: number, message: string, uuid?: string) =>
  ({
    type: 'SLICER_SUBMIT_MULTIPLE_WITH_PARAMS_FAILURE',
    statusCode,
    message,
    uuid
  } as const);

export const slicerSubmitMultipleWithParams = (
  prints: readonly ExperimentFile[],
  modelNumber: number,
  queueFor = 'print',
  uuid = uuidv4()
) => {
  if (!prints)
    return async (dispatch: ThunkDispatch<any, any, any>) =>
      dispatch(slicerSubmitMultipleWithParamsFailure(500, 'No prints specified'));

  const files = prints.map(p => {
    const extruder = p.parameters && !isNaN(p.parameters.extruder) ? p.parameters.extruder : 0;
    const layerHeight = p.parameters && !isNaN(p.parameters.layerHeight) ? p.parameters.layerHeight : 0.2;
    const needleGauge = p.parameters && !isNaN(p.parameters.needleGauge!) ? p.parameters.needleGauge : 0;
    const speed = p.parameters && !isNaN(p.parameters.speed) ? p.parameters.speed : 10;
    // const speedFirstLayer = p.parameters && !isNaN(p.parameters.speedFirstLayer) ? p.parameters.speedFirstLayer : speed;
    const xOffset = p.parameters && !isNaN(p.parameters.xOffset) ? p.parameters.xOffset : 0;
    const yOffset = p.parameters && !isNaN(p.parameters.yOffset) ? p.parameters.yOffset : 0;
    const zOffset = p.parameters && !isNaN(p.parameters.zOffset) ? p.parameters.zOffset : 0;
    const infillType = p.parameters && p.parameters.infillType ? p.parameters.infillType.toLowerCase() : 'none';
    const infillDistance = p.parameters && !isNaN(p.parameters.infillDistance) ? p.parameters.infillDistance : 1;
    const infillDirection =
      p.parameters && !isNaN(p.parameters.infillDirection) ? `[${p.parameters.infillDirection}]` : '[0]';
    const surfaceMode =
      p.parameters && p.parameters.infillType && p.parameters.infillType.toLowerCase() !== 'none'
        ? 'normal'
        : 'surface';

    // Convert needle gauge to diameter
    let needleDiameter = layerHeight;
    switch (Math.floor(needleGauge!)) {
      case 12:
        needleDiameter = 2.26;
        break;
      case 13:
        needleDiameter = 1.778;
        break;
      case 14:
        needleDiameter = 1.6;
        break;
      case 15:
        needleDiameter = 1.37;
        break;
      case 16:
        needleDiameter = 1.2;
        break;
      case 17:
        needleDiameter = 1.04;
        break;
      case 18:
        needleDiameter = 0.84;
        break;
      case 19:
        needleDiameter = 0.7;
        break;
      case 20:
        needleDiameter = 0.6;
        break;
      case 21:
        needleDiameter = 0.51;
        break;
      case 22:
        needleDiameter = 0.41;
        break;
      case 23:
        needleDiameter = 0.34;
        break;
      case 24:
        needleDiameter = 0.3;
        break;
      case 25:
        needleDiameter = 0.26;
        break;
      case 26:
        needleDiameter = 0.24;
        break;
      case 27:
        needleDiameter = 0.21;
        break;
      case 28:
        needleDiameter = 0.19;
        break;
      case 30:
        needleDiameter = 0.16;
        break;
      case 32:
        needleDiameter = 0.1;
        break;
      case 34:
        needleDiameter = 0.08;
        break;
      default:
        needleDiameter = layerHeight;
    }

    // Build rotation matrix
    const m = new Matrix4().identity();
    if (
      (p.parameters && p.parameters.xRotation && p.parameters.xRotation !== 0) ||
      (p.parameters && p.parameters.yRotation && p.parameters.yRotation !== 0) ||
      (p.parameters && p.parameters.zRotation && p.parameters.zRotation !== 0)
    ) {
      const rotationEuler = new Euler(
        parseFloat(p.parameters.xRotation as any) * (Math.PI / 180.0),
        parseFloat(p.parameters.yRotation as any) * (Math.PI / 180.0),
        parseFloat(p.parameters.zRotation as any) * (Math.PI / 180.0),
        'XYZ'
      );

      m.makeRotationFromEuler(rotationEuler);
    }

    // Bake scale into transformation matrix
    if (p.parameters && !isNaN(p.parameters.scale)) {
      const scale = parseFloat(p.parameters.scale as any);

      m.multiplyScalar(scale);
    }

    m.transpose(); // Three uses column-major ordering, we want row-major
    const transformationMatrix =
      `"[[${m.elements[0]},${m.elements[1]},${m.elements[2]}], ` +
      `[${m.elements[4]},${m.elements[5]},${m.elements[6]}], ` +
      `[${m.elements[8]},${m.elements[9]},${m.elements[10]}]]"`;

    let fid;
    if (p.file) {
      fid = p.file._id;
    } else if (p.structure) {
      fid = p.structure.sid;
    }

    const fileObject = {
      file: fid,
      slicingParameters: {
        type: 'json',
        default: {
          extruder_nr: extruder
        },
        settings: {
          lineWidth: needleDiameter,
          speed,
          // speedFirstLayer,
          transformationMatrix,
          xOffset,
          yOffset,
          zOffset,
          infillType,
          infillDistance,
          infillDirection,
          surfaceMode
        }
      }
    };

    if (p.structure) {
      //@ts-expect-error
      fileObject.file = {
        ...p.structure,
        type: 'structure'
      };
    }

    return fileObject;
  });

  const printerParameters = {
    modelNumber
  };

  const slicingParameters = {
    layerHeight: prints[0].parameters.layerHeight || 0.2
  };

  if (files[0] && files[0].slicingParameters && files[0].slicingParameters.settings) {
    slicingParameters.layerHeight = files[0].slicingParameters.settings.lineWidth;
  } else {
    slicingParameters.layerHeight = 0.2;
  }

  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(slicerSubmitMultipleWithParamsAction(prints, slicingParameters, printerParameters, queueFor, uuid));

    return slicerSlice(files, slicingParameters, printerParameters, uuid)
      .then(e => {
        if (e.statusCode === 200 && e.status === true) {
          dispatch(slicerSubmitMultipleWithParamsSuccess(e.status, queueFor, uuid));
          return {
            success: true,
            status: e.sliceStatus,
            queueFor
          } as const;
        }
        dispatch(slicerSubmitMultipleWithParamsFailure(e.statusCode, e.message, uuid));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(slicerSubmitMultipleWithParamsFailure(e.statusCode, e.message, uuid));
        return { success: false } as const;
      });
  };
};

//
// Low-level printer comm actions
//
const printerSendMessageAction = (printer: string, message: string | readonly string[]) =>
  ({
    type: 'PRINTER_SEND_MESSAGE',
    printer,
    message
  } as const);

const printerSendMessageSuccess = (printer: string, message: string | readonly string[]) =>
  ({
    type: 'PRINTER_SEND_MESSAGE_SUCCESS',
    printer,
    message
  } as const);

const printerSendMessageError = (printer: string, message: string | readonly string[]) =>
  ({
    type: 'PRINTER_SEND_MESSAGE_ERROR',
    printer,
    message
  } as const);

const printerSetManualSendStatus = (printer: string, status: CommState['manualSendStatus']) =>
  ({
    type: 'PRINTER_SET_MANUAL_SEND_STATUS',
    printer,
    status
  } as const);

const printerAppendToCommandBuffer = (printer: string, command: string | readonly string[]) =>
  ({
    type: 'PRINTER_APPEND_TO_COMMAND_BUFFER',
    printer,
    command
  } as const);

export function printerSendMessage(printer: string, message: string | readonly string[], manual = false) {
  return async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    let useAlleviClient = getState().adapter.serialNumber === printer;

    if (message !== undefined && message.toString().length > 0) {
      const isBuffering = getState().comm?.bufferCommands;

      if (isBuffering) {
        dispatch(printerAppendToCommandBuffer(printer, message));
        return true;
      } else {
        dispatch(printerSendMessageAction(printer, message));
        return (useAlleviClient ? sendAlleviAdapterCommand(message) : printerSendCommand(printer, message))
          .then(e => {
            if (e.statusCode === 200) {
              dispatch(printerSendMessageSuccess(printer, message));

              if (manual) {
                dispatch(printerSetManualSendStatus(printer, 1));

                setTimeout(() => dispatch(printerSetManualSendStatus(printer, 0)), 3000);
              }
            }
          })
          .catch(e => {
            dispatch(printerSendMessageError(printer, e.message));

            if (manual) {
              dispatch(printerSetManualSendStatus(printer, 2));

              setTimeout(() => dispatch(printerSetManualSendStatus(printer, 0)), 3000);
            }
          });
      }
    }

    dispatch(printerSendMessageError(printer, message));

    if (manual) {
      dispatch(printerSetManualSendStatus(printer, 2));

      setTimeout(() => dispatch(printerSetManualSendStatus(printer, 0)), 3000);
    }
    return false;
  };
}

//
// Printer command buffering logic
//
const printerStartBufferingCommandsAction = (printer: string) =>
  ({
    type: 'PRINTER_START_BUFFERING_COMMANDS',
    printer
  } as const);

export function printerStartBufferingCommands(printer: string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerStartBufferingCommandsAction(printer));
}

const printerStopBufferingCommandsAction = (printer: string) =>
  ({
    type: 'PRINTER_STOP_BUFFERING_COMMANDS',
    printer
  } as const);

export function printerStopBufferingCommands(printer: string) {
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerStopBufferingCommandsAction(printer));
}

const printerSendCommandBufferAction = (printer: string, buffer: readonly string[]) =>
  ({
    type: 'PRINTER_SEND_COMMAND_BUFFER',
    printer,
    buffer
  } as const);

export function printerSendCommandBuffer(printer: string) {
  return async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const commandBuffer = getState()?.comm?.commandBuffer[printer];

    if (commandBuffer && commandBuffer.toString().length > 0) {
      dispatch(printerSendCommandBufferAction(printer, commandBuffer));
      return dispatch(printerSendMessage(printer, commandBuffer));
    }

    return false;
  };
}

//
// High-level printer comm actions
//
export function printerClearLogs(printer: string) {
  const message = gcode.clearLogs();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerCancel(printer: string) {
  const message = gcode.cancelPrint();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerCancelAndMoveUp(printer: string, wellCount: number) {
  const message = gcode.cancelPrintAndMoveUp(wellCount || 1); // if wellcount is "Zero" or "undefined", pass "One"
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerPause(printer: string) {
  const message = gcode.pausePrint();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerResume(printer: string) {
  const message = gcode.resumePrint();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetExtruder(printer: string, extruder: number) {
  const message = gcode.setExtruder(extruder);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerAutoCalibrate(printer: string, extruder: number) {
  const message = gcode.autoCalibrate(extruder);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerAutoCalibrateAll(printer: string) {
  const message = gcode.autoCalibrateAll();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerCancelAutoCalibrate(printer: string) {
  const message = gcode.cancelAutocalibrate();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetManualCalibration(
  printer: string,
  extruder: number,
  posX: number,
  posY: number,
  posZ: number
) {
  const message = gcode.setManualCalibration(extruder, posX, posY, posZ);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetPosition(printer: string, extruder: number, posX: number, posY: number, posZ: number) {
  const message = gcode.setPosition(extruder, posX, posY, posZ);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerMoveRelative(printer: string, extruder: number, axis: Axis, delta: number) {
  const message = gcode.moveRelative(extruder, axis, delta);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerMoveEAxis(printer: string, extruder: number, pos: number) {
  const message = gcode.moveEAxis(extruder, pos);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerZeroAxis(printer: string, extruder: number, axis: Axis) {
  const message = gcode.zeroAxis(extruder, axis);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerHomeAxis(printer: string, extruder: number, axis: Axis) {
  const message = gcode.homeAxis(extruder, axis);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetEnclosureLEDs(printer: string, r: number, g: number, b: number) {
  const message = gcode.setEnclosureLEDs(r, g, b);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerStopMotors(printer: string) {
  const message = gcode.stopMotors();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetWellPlate(printer: string, wellCount: number) {
  const message = gcode.setWellPlate(wellCount);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetWellPlateAndCenter(printer: string, wellCount: number) {
  const message = gcode.setWellPlateAndCenter(wellCount);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerDisableTemperatureControl(printer: string, extruder: number) {
  const message = gcode.disableTemperatureControl(extruder);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetTemperature(printer: string, extruder: number, temp: number) {
  const message = gcode.setTemperature(extruder, temp);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetTemperatureAndWait(printer: string, extruder: number, temp: number) {
  const message = gcode.setTemperatureAndWait(extruder, temp);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerDisableBedTemperatureControl(printer: string) {
  const message = gcode.disableBedTemperatureControl();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetBedTemperature(printer: string, temp: number) {
  const message = gcode.setBedTemperature(temp);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetPressure(printer: string, extruder: number, pressure: number) {
  const message = gcode.setPressure(extruder, pressure);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerExtrudeStart(printer: string, extruder: number) {
  const message = gcode.extrudeStart(extruder);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerExtrudeStop(printer: string, extruder: number) {
  const message = gcode.extrudeStop(extruder);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetManualCrosslinking(printer: string, type: number, intensity: number, active: boolean) {
  const message = gcode.setManualCrosslinking(type, intensity, active);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerDisableCrosslinkingDuringPrint(printer: string) {
  const message = gcode.disableCrosslinkingDuringPrint();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetCrosslinkingDuringPrint(
  printer: string,
  intensity: number,
  type: number,
  duration: number,
  frequency: number
) {
  const message = gcode.setCrosslinkingDuringPrint(intensity, type, duration, frequency);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerDisableCrosslinkingAfterPrint(printer: string) {
  const message = gcode.disableCrosslinkingAfterPrint();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerSetCrosslinkingAfterPrint(printer: string, intensity: number, type: number, duration: number) {
  const message = gcode.setCrosslinkingAfterPrint(intensity, type, duration);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerPrintFile(printer: string, url: string, checksum: string) {
  const message = gcode.printFile(url, checksum);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerAutocalibrateCenterWellplateAndPrintFile(
  printer: string,
  url: string,
  wellType: number,
  wellIndex: number,
  checksum: string | undefined
) {
  const message = gcode.autocalibrateCenterWellplateAndPrintFile(url, wellType, wellIndex, checksum);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerCenterWellplateAndPrintFile(
  printer: string,
  url: string,
  wellType: number,
  wellIndex: number,
  checksum: string | undefined
) {
  const message = gcode.centerWellplateAndPrintFile(url, wellType, wellIndex, checksum);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerPrintFileInWellplate(
  printer: string,
  url: string,
  wellplate: number,
  checksum: string | undefined
) {
  const message = gcode.printFileInWellplate(url, wellplate, checksum);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerAutocalibrateAndPrintFileInWellplate(
  printer: string,
  url: string,
  wellplate: number,
  checksum: string | undefined
) {
  const message = gcode.autocalibrateAndPrintFileInWellplate(url, wellplate, checksum);
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerDisconnectWifi(printer: string) {
  const message = gcode.disconnectWifi();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}

export function printerRestoreWifi(printer: string) {
  const message = gcode.restoreWifi();
  return async (dispatch: ThunkDispatch<any, any, any>) => dispatch(printerSendMessage(printer, message));
}
