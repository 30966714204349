import { captureException, Severity, withScope } from '@sentry/react';

export const logErrorBoundary = (error: Error & { cause?: Error }, componentStack: string) => {
  console.error(error, componentStack);
  withScope(() => {
    const errorBoundaryError = new Error(error.message);
    errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
    errorBoundaryError.stack = componentStack;

    error.cause = errorBoundaryError;

    captureException(error, { contexts: { react: { componentStack } } });
  });
};

const sentryErrorLogger = (tag: string) => (error: Error) => {
  console.error(error);
  withScope(scope => {
    scope.setTags({ component: tag, uncaught: 'true' });
    captureException(error);
  });
};

export const onUncaughtErrorInPromise = sentryErrorLogger('promise');

export const logError = <E>(error: Error, extra?: E) => {
  withScope(scope => {
    scope.setLevel(Severity.Error);
    scope.setExtra('extra', extra);
    captureException(error);
  });
};

global.addEventListener('unhandledrejection', event => {
  onUncaughtErrorInPromise(event.reason);
});
