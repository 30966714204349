import { verifyAndRelayRequest } from '../utils/index';

/*
Response Format

{
    "status": true,
    "organization": {
        "members": [<emails of members>],
        "administrators": [<emails of administrators>],
        "teams": [
            {
                "members": [<emails of members>],
                "administrators": [<emails of administrators>],
                "_id": "5e54972576b165e39706945e",
                "access": "PRIVATE",
                "organization": "5e54972576b165e39706945d",
                "name": "Untitled Team",
                "type": "STANDARD",
                "createdAt": "2020-02-25T03:40:21.436Z",
                "updatedAt": "2020-02-25T15:52:50.131Z"
            }
            {team2 if exists},
            {team3 if exists}
        ],
        "_id": "5e54972576b165e39706945d",
        "name": "Updated Name",
        "access": "PRIVATE",
        "type": "STANDARD",
        "subscriptionData": {
            "tier": "BASIC"
        },
        "createdAt": "2020-02-25T03:40:21.026Z",
        "updatedAt": "2020-02-25T22:33:56.356Z"
    }
}

The teams array will always contain only the team relevant to the action being performed, that is if an
organization has two teams and a member is added to team2, the organization.teams array will only the
team2 document. AN example where multiple teams will be returned is when you read all organizations
and each organization.teams array will have all teams for that org
*/

export async function organizationCreate(name, access = 'PRIVATE', type = 'STANDARD') {
  return verifyAndRelayRequest({
    route: '/organization/create',
    method: 'POST',
    protected: true,
    data: {
      access,
      name,
      type
    }
  });
}

export async function organizationRead(filter) {
  return verifyAndRelayRequest({
    route: '/organization/read',
    method: 'POST',
    protected: true,
    data: {
      filter
    }
  });
}

export async function organizationUpdate(filter, update) {
  return verifyAndRelayRequest({
    route: '/organization/update',
    method: 'POST',
    protected: true,
    data: {
      filter,
      update
    }
  });
}

export async function organizationAddAdministrator(email = []) {
  return verifyAndRelayRequest({
    route: '/organization/add/administrator',
    method: 'POST',
    protected: true,
    data: {
      email
    }
  });
}

export async function organizationRemoveAdministrator(email = []) {
  return verifyAndRelayRequest({
    route: '/organization/remove/administrator',
    method: 'POST',
    protected: true,
    data: {
      email
    }
  });
}

export async function organizationTeamAddAdministrator(email = []) {
  return verifyAndRelayRequest({
    route: '/organization/team/add/administrator',
    method: 'POST',
    protected: true,
    data: {
      email
    }
  });
}

export async function organizationTeamRemoveAdministrator(email = []) {
  return verifyAndRelayRequest({
    route: '/organization/team/remove/administrator',
    method: 'POST',
    protected: true,
    data: {
      email
    }
  });
}

export async function organizationTeamAddMember(email = []) {
  return verifyAndRelayRequest({
    route: '/organization/team/add/member',
    method: 'POST',
    protected: true,
    data: {
      email
    }
  });
}

export async function organizationTeamRemoveMember(email = []) {
  return verifyAndRelayRequest({
    route: '/organization/team/remove/member',
    method: 'POST',
    protected: true,
    data: {
      email
    }
  });
}

export async function organizationTeamSwitch(targetOrganization, targetTeam) {
  return verifyAndRelayRequest({
    route: '/organization/team/switch',
    method: 'POST',
    protected: true,
    data: {
      targetOrganization,
      targetTeam
    }
  });
}

export async function organizationTeamAdd(name, access = 'PRIVATE') {
  return verifyAndRelayRequest({
    route: '/organization/team/add',
    method: 'POST',
    protected: true,
    data: {
      name,
      access
    }
  });
}

export async function organizationTeamReadCurrent() {
  return verifyAndRelayRequest({
    route: '/organization/team/read/current',
    method: 'GET',
    protected: true,
    data: {}
  });
}

export async function organizationTeamUpdateMemberPassword(administratorPassword, memberEmail, memberNewPassword) {
  return verifyAndRelayRequest({
    route: '/organization/team/update/member/password',
    method: 'POST',
    protected: true,
    data: {
      administratorPassword,
      memberEmail,
      memberNewPassword
    }
  });
}

// sets home org team pair to current org team pair
export async function organizationTeamSetHome() {
  return verifyAndRelayRequest({
    route: '/organization/team/set/home',
    method: 'GET',
    protected: true,
    data: {}
  });
}

// returns map of resources that were successfully transferred
export async function organizationTeamResourceTransfer(targetOrganization, targetTeam) {
  return verifyAndRelayRequest({
    route: '/organization/team/resource/transfer',
    method: 'POST',
    protected: true,
    data: {
      targetOrganization,
      targetTeam,
      preview: false
    }
  });
}

// returns map of resources that will be transferred
export async function organizationTeamResourceTransferPreview(targetOrganization, targetTeam) {
  return verifyAndRelayRequest({
    route: '/organization/team/resource/transfer',
    method: 'POST',
    protected: true,
    data: {
      targetOrganization,
      targetTeam,
      preview: true
    }
  });
}

// returns resource map as follows: (all resources but e.g. is for file only)
/*
  "file": {
    "total": 4, (PUBLIC + PRIVATE + DEFAULT)
    "totalTeam": 2, (PUBLIC + PRIVATE )
    "default": 2, (DEFAULT)
    "public": 0, (PUBLIC)
    "private": 2 (PRIVATE)
  },
*/
export async function organizationTeamResourceCount() {
  return verifyAndRelayRequest({
    route: '/organization/team/resource/count',
    method: 'GET',
    protected: true,
    data: {}
  });
}

/*
returns:
{
    "status": true,
    "organization": <Org Object>
    "token": <auth token for default team>
}
*/
export async function organizationTeamLeave() {
  return verifyAndRelayRequest({
    route: '/organization/team/leave',
    method: 'GET',
    protected: true,
    data: {}
  });
}

export async function organizationTeamDelete() {
  return verifyAndRelayRequest({
    route: '/organization/team/delete',
    method: 'DELETE',
    protected: true,
    data: {}
  });
}

// returns organization updated with new plan
export async function organizationPlanActivate(activationKey) {
  return verifyAndRelayRequest({
    route: '/organization/plan/activate',
    method: 'POST',
    protected: true,
    data: {
      activationKey
    }
  });
}

export async function organizationSubscriptionTransfer(targetOrganization) {
  return verifyAndRelayRequest({
    route: '/organization/subscription/transfer',
    method: 'POST',
    protected: true,
    data: {
      targetOrganization
    }
  });
}
