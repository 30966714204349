import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { StylesProvider, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import { ThemeProvider } from 'styled-components';
import { din } from './styles/variables';

import { AlleviAdapterConnection } from './components/AlleviAdapterConnection';
import { useOrganizationActions, useOrganizationState, useUserActions, useUserState } from './state/redux/hooks';
import { logErrorBoundary } from './error/error';
import { AdminRoute } from './components/Routes/AdminRoute';
import { PrivateRoute } from './components/Routes/PrivateRoute';

// Route-based code splitting
const ErrorPage = lazy(() => import(/* webpackChunkName: 'ErrorPage' */ './pages/Error'));
const NotFound = lazy(() => import(/* webpackChunkName: 'NotFoundPage' */ './pages/NotFound'));

const Account = lazy(() => import(/* webpackChunkName: 'Account' */ './pages/Account'));
const Login = lazy(() => import(/* webpackChunkName: 'LoginPage' */ './pages/Login'));
const Register = lazy(() => import(/* webpackChunkName: 'Register' */ './pages/Register'));
const ForgotPassword = lazy(() => import(/* webpackChunkName: 'ForgotPassword' */ './pages/ForgotPassword'));
const ResetPassword = lazy(() => import(/* webpackChunkName: 'ResetPassword' */ './pages/ResetPassword'));
const ActivatePlan = lazy(() => import(/* webpackChunkName: 'ActivatePlan' */ './pages/ActivatePlan'));

const Team = lazy(() => import(/* webpackChunkName: 'TeamPage' */ './pages/Team'));
const Teams = lazy(() => import(/* webpackChunkName: 'TeamsPage' */ './pages/Teams'));
const CreateTeam = lazy(() => import(/* webpackChunkName: 'CreateTeamPage' */ './pages/CreateTeam'));

const Printers = lazy(() => import(/* webpackChunkName: 'PrintersPage' */ './pages/Printers'));
const RegisterPrinter = lazy(() => import(/* webpackChunkName: 'Register' */ './pages/RegisterPrinter'));
const GeneratePrinterToken = lazy(
  () => import(/* webpackChunkName: 'GeneratePrinterToken' */ './pages/GeneratePrinterToken')
);

const CreateDevice = lazy(() => import(/* webpackChunkName: 'CreateDevice' */ './pages/CreateDevice'));
const RegisterDevice = lazy(() => import(/* webpackChunkName: 'RegisterDevice' */ './pages/RegisterDevice'));
const ManageDevice = lazy(() => import(/* webpackChunkName: 'ManageDevice' */ './pages/ManageDevice'));
const Devices = lazy(() => import(/* webpackChunkName: 'Devices' */ './pages/Devices'));

const Projects = lazy(() => import(/* webpackChunkName: 'Projects' */ './pages/Projects'));
const ProjectEditor = lazy(() => import(/* webpackChunkName: 'ProjectEditor' */ './pages/ProjectEditor'));
const ProjectPrint = lazy(() => import(/* webpackChunkName: 'ProjectPrint' */ './pages/ProjectPrint'));
const ExperimentTemplates = lazy(
  () => import(/* webpackChunkName: 'ExperimentTemplates' */ './pages/ExperimentTemplates')
);

const Admin = lazy(() => import(/* webpackChunkName: 'Admin' */ './pages/Admin'));
const About = lazy(() => import(/* webpackChunkName: 'About' */ './pages/About'));
const UnregisterPrinter = lazy(() => import(/* webpackChunkName: 'Unregister' */ './pages/UnregisterPrinter'));
const CreatePrinter = lazy(() => import(/* webpackChunkName: 'CreatePrinter' */ './pages/CreatePrinter'));
const DeletePrinter = lazy(() => import(/* webpackChunkName: 'DeletePrinter' */ './pages/DeletePrinter'));

// Customize Material UI theme
const theme = createMuiTheme({
  shape: {
    borderRadius: 0
  },

  typography: {
    fontFamily: din
  },

  props: {
    MuiButtonBase: {
      disableRipple: true // No more ripple, on the whole application
    }
  },

  palette: {
    background: {
      default: '#FFFFFF'
    }
  }
});

// Error boundary
class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, { componentStack }: React.ErrorInfo) {
    logErrorBoundary(error, componentStack);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Suspense fallback={<div />}>
          <ErrorPage />
        </Suspense>
      );
    }

    return this.props.children;
  }
}

const App = (props: {}) => {
  const user = useUserState();
  const organization = useOrganizationState();

  const userActions = useUserActions();
  const organizationActions = useOrganizationActions();

  const onPrem =
    (window.hostEnvironment && window.hostEnvironment === 'ON_PREM') ||
    process.env.REACT_APP_HOST_ENVIRONMENT === 'ON_PREM';

  useEffect(() => {
    if (sessionStorage.getItem('id_token')) {
      userActions.getUser().then(() => {
        if (user?.authenticated) {
          organizationActions.getOrganization();
        }
      });
    }
  }, []); /*eslint-disable-line react-hooks/exhaustive-deps */ //TODO: verify

  useEffect(() => {
    if (user.authenticated && organization.organizations?.length === 0) {
      organizationActions.getOrganization();
    }
  }, [user.authenticated]); /*eslint-disable-line react-hooks/exhaustive-deps */ //TODO: verify

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <AlleviAdapterConnection />
          <BrowserRouter>
            <Suspense fallback={<div />}>
              <Switch>
                <PrivateRoute path="/" exact component={Printers} />
                <PrivateRoute path={['/printers', '/printers/:serialNumber']} component={Printers} />
                <PrivateRoute path="/projects" component={Projects} />
                <PrivateRoute path="/experiments" component={Projects} />
                <PrivateRoute path={['/create-project', '/edit-project']} component={ProjectEditor} />
                <PrivateRoute path={['/create-experiment', '/edit-experiment']} component={ProjectEditor} />
                <PrivateRoute path={['/print-project', '/print-experiment']} component={ProjectPrint} />
                <PrivateRoute path={'/experiment-templates'} component={ExperimentTemplates} />
                <Route path="/login" component={Login} />
                <Route path="/create-account" component={Register} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/account" component={Account} />
                <PrivateRoute path="/activate" component={ActivatePlan} />
                <PrivateRoute path="/teams/:teamId" component={Team} />
                <PrivateRoute path="/teams" component={Teams} />
                <PrivateRoute path="/create-team" component={CreateTeam} />
                <PrivateRoute path="/register" component={RegisterPrinter} />
                <PrivateRoute path="/about" component={About} />

                {/*Admin Routes*/}
                <AdminRoute path="/admin" component={Admin} />
                <AdminRoute path="/unregister" component={UnregisterPrinter} />
                <AdminRoute path="/delete-printer" component={DeletePrinter} />

                {!onPrem
                  ? [
                      /* Cloud specific routes */
                      <AdminRoute key="/create-printer" path="/create-printer" component={CreatePrinter} />,
                      <PrivateRoute key="/devices" path="/devices" component={Devices} />,
                      <PrivateRoute key="/create-device" path="/create-device" component={CreateDevice} />,
                      <PrivateRoute key="/register-device" path="/register-device" component={RegisterDevice} />,
                      <PrivateRoute
                        key="/generate-printer-token"
                        path={['/generate-printer-token', '/generate-printer-token/:serialNumber']}
                        component={GeneratePrinterToken}
                      />,
                      <Route key="/reset-password" path="/reset-password" component={ResetPassword} />
                    ]
                  : [
                      /* On-prem specific routes */
                      <PrivateRoute key="/manage-device" path="/manage-device" component={ManageDevice} />
                    ]}

                {/* 404 Page */}
                <Route path="/404" component={NotFound} />
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
