/**
 * Based on https://bitbucket.org/allevi/allevi-client/
 * A wrapper around the end points exposed by
 * [allevi-client](https://bitbucket.org/allevi/allevi-client/src/development/README.md)
 * which in turn exposes the endpoints exposed by
 * [allevi-adapter](https://bitbucket.org/allevi/os-allevi2adapter/) (see allevi-adapter/README.md)
 */
import axios, { AxiosResponse } from 'axios';

export const ip = '127.0.0.1';
export const alleviClientUrl = `http://${ip}:8000`;

export interface AlleviClientState {
  networkInterfaces: readonly string[];
  printerNetworkInterface: number;
  clientVersion: string;
  status: boolean;
}

export interface AlleviAdapterState {
  state: {
    status: 'UNKNOWN' | 'IDLE' | 'STANDBY' | 'TEMPING' | 'ONLINE' | 'OFFLINE' | 'ERROR';
    serialNumber: string;
    supplyPressure: number;
    systemTemp: number;
    modelNumber: number;
    located: boolean;
    positionMode: boolean;
    buildNumber: number;
    activeExtruder: number;
    Wellplate: { Type: number; Well: number };
    errorCode: unknown[];
    crosslinking: unknown;
    extruders: unknown;
    bed: { currentTemp: number; targetTemp: number; tempActive: boolean };
    firmwareVersion: string;
    lastPrintKilled: boolean;
  };
  status: boolean;
  lastUpdate?: number;
}

export interface AlleviAdapterCommandsResponse {
  status: boolean;
}

export interface AlleviAdapterFileResponse {
  status: boolean;
  path: string;
}

export async function getAlleviClientState(abortSignal?: AbortSignal) {
  // TODO: PORT_SCAN
  const stateResult = await axios.get<AlleviClientState, AxiosResponse<AlleviClientState>>(
    `${alleviClientUrl}/client-state`,
    {
      timeout: 5000,
      //@ts-expect-error
      abort: abortSignal
    }
  );
  if (stateResult.status === 200) {
    return stateResult.data;
  } else {
    console.log('Client state response', stateResult);
    throw new Error('Invalid state response');
  }
}

export async function getAlleviAdapterState(abortSignal?: AbortSignal) {
  // TODO: PORT_SCAN
  const stateResult = await axios.get<AlleviAdapterState, AxiosResponse<AlleviAdapterState>>(
    `${alleviClientUrl}/state`,
    {
      timeout: 5000,
      //@ts-expect-error
      abort: abortSignal
    }
  );
  if (stateResult.status === 200) {
    return stateResult.data;
  } else {
    console.log('State response', stateResult);
    throw new Error('Invalid state response');
  }
}

export async function getAlleviAdapterLogs() {
  const response = await axios.post<string>(
    `${alleviClientUrl}/logs/read`,
    { count: 10000 },
    { headers: { 'Content-Type': 'application/json' } }
  );

  if (response.status !== 200) throw new Error('Fetching logs failed');
  return response.data as string;
}

async function relayCommandString(commandString: string) {
  const payload = new FormData();
  payload.append('commands', commandString);

  const response = await axios.post<AlleviAdapterCommandsResponse>(`${alleviClientUrl}/commands`, payload, {
    headers: {
      'Content-Type': 'mime/multipart'
    }
  });

  if (response.status !== 200) throw new Error('Relaying commands failed');
  if (response.data.status !== true) {
    console.error(response.data);
    throw new Error('Printer responded with error status');
  }
}

async function relayFile(fileUrl: string): Promise<string> {
  // download file
  const fileResponse = await fetch(fileUrl, {
    headers: {
      'Content-Type': 'text/x.gcode'
    }
  });
  if (fileResponse.status !== 200) throw new Error('Downloading file failed');

  // prepare upload request
  const payload = new FormData();
  payload.append('file', new File([await fileResponse.blob()], 'PrintFile.gcode', { type: 'text/x.gcode' }));

  // upload file
  const response = await axios.post<AlleviAdapterFileResponse>(`${alleviClientUrl}/file`, payload, {
    headers: {
      'Content-Type': 'mime/multipart'
    }
  });

  if (response.status !== 200) throw new Error('Relaying file failed');
  if (response.data.status !== true) {
    console.error(response.data);
    throw new Error('Printer responded with error status');
  }
  return response.data.path;
}

//internal helper, exported only for testing purposes
export async function preprocessCommands(message: string | readonly string[]) {
  const messageArray: readonly string[] = Array.isArray(message) ? message : [message];
  console.log('commands', messageArray);

  const tempFileCache: Record<string, string> = {};
  let commandString: string = '';

  // iterate over command array
  for (let index = 0; index < messageArray.length; index++) {
    const command = messageArray[index];

    // split individual commands by whitespace and check for urls
    const commandFields = command.split(' ');
    for (let commandFieldIndex = 0; commandFieldIndex < commandFields.length; commandFieldIndex++) {
      const commandField = commandFields[commandFieldIndex];
      if (commandField.startsWith('https://')) {
        if (!tempFileCache[commandField]) {
          // not found in cache
          const filePath = await relayFile(commandField);
          tempFileCache[commandField] = filePath;
          commandFields[commandFieldIndex] = filePath;
        } else {
          commandFields[commandFieldIndex] = tempFileCache[commandField];
        }
        // process file
      }
    }

    let processedCommand = '';
    if (commandFields[0] === 'F1') {
      processedCommand = `P1 ${commandFields[1]}`;
    } else if (commandFields[0] === 'F2') {
      //no equivalent P* command exists for F2, so we need to adapt the gcode ourselves
      //TODO: ideally the gcode generator would deprecate the F2 command
      const wellplate = parseInt(commandFields[2]);
      if (wellplate === 0) {
        processedCommand = `C100 T0 W0,P1 ${commandFields[1]}`;
      } else {
        //repeat the print command to print in each well
        processedCommand = Array.from({ length: wellplate })
          .map((_, i) => `C100 T${wellplate} W${i},P1 ${commandFields[1]}`)
          .join(',');
      }
    } else {
      processedCommand = commandFields.join(' ');
    }

    if (index > 0) {
      commandString += ',' + processedCommand;
    } else {
      commandString = processedCommand;
    }
  }
  console.log('postprocessed command string', commandString);
  return commandString;
}

export async function sendAlleviAdapterCommand(message: string | readonly string[]) {
  const commandString = await preprocessCommands(message);
  await relayCommandString(commandString);
  return { statusCode: 200 }; //mock successfull response
}

export function subscribeToAlleviAdapterState(
  serialNumber: string,
  callback: (parsedData: AlleviAdapterState) => void
) {
  let aborted = false;

  const pollAdapterState = async () => {
    try {
      const parsedData = await getAlleviAdapterState();
      if (parsedData.state.serialNumber !== serialNumber)
        throw new Error(`Wrong printer serial number: ${parsedData.state.serialNumber} != ${serialNumber}`);
      if (!aborted) callback(parsedData);
    } catch (e) {
      console.log('Ping failed', e);
    }
  };

  pollAdapterState();

  let pollInterval: number | undefined = window.setInterval(pollAdapterState, 6000);

  function cleanup() {
    window.clearInterval(pollInterval);
    pollInterval = undefined;
    aborted = true;
  }

  return cleanup;
}
