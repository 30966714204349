import { ActionCreatorsMapObject, AnyAction, bindActionCreators as bindActionCreators_, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

export function bindActionCreators<M extends ActionCreatorsMapObject>(map: M, dispatch: Dispatch<AnyAction>) {
  return bindActionCreators_<M, { [P in keyof M]: RemoveDispatch<M[P]> }>(map, dispatch);
}

export type RemoveDispatch<T extends Function> = T extends (
  ...args: infer EE
) => (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => infer R
  ? (...args: EE) => R
  : T;
