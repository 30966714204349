import { verifyAndRelayRequest } from '../utils/index';

/*
 returns:
 {
   status: true
   nuc: <NUC Document>
 }
*/
export async function nucCreate(access = 'DEFAULT', name, serialNumber, nucData, createBalena = true) {
  return verifyAndRelayRequest({
    route: '/nuc/create',
    method: 'POST',
    protected: true,
    data: {
      access,
      name,
      serialNumber,
      nucData,
      createBalena
    }
  });
}

/*
 returns:
 {
   status: true
   nuc: <NUC Document>
   token: <NUC init token>
 }
*/
export async function nucRegister(serialNumber) {
  return verifyAndRelayRequest({
    route: '/nuc/register',
    method: 'POST',
    protected: true,
    data: {
      serialNumber
    }
  });
}

export async function nucUnregister(serialNumber) {
  return verifyAndRelayRequest({
    route: '/nuc/unregister',
    method: 'POST',
    data: {
      serialNumber
    },
    protected: true
  });
}

export async function nucRead(filter, options = {}) {
  return verifyAndRelayRequest({
    route: '/nuc/read',
    method: 'POST',
    data: {
      filter,
      options
    },
    protected: true
  });
}

/*
 returns:
 {
  status: true
  firstRun: <True | False>
 }
*/
export async function nucIsFirstRun() {
  return verifyAndRelayRequest({
    route: '/nuc/is/first/run',
    method: 'POST',
    protected: false
  });
}

/*
 returns:
 {
   status: true
   tokenIsValid: <True | False>
   firstRun: <True | False>
 }
*/
export async function nucValidateInitializationToken(token) {
  return verifyAndRelayRequest({
    route: '/nuc/validate/initialization/token',
    method: 'POST',
    protected: false,
    data: {
      token
    }
  });
}

/*
 returns:
 {
   status: true
   token: <Token>
 }
*/
export async function nucGenerateInitializationToken(serialNumber, printerSerialNumber) {
  return verifyAndRelayRequest({
    route: '/nuc/generate/initialization/token',
    method: 'POST',
    protected: true,
    data: {
      serialNumber
    }
  });
}

/*
 returns:
 {
   status: true
   token: <Token>
 }
*/
export async function nucGeneratePrinterToken(serialNumber, printerSerialNumber) {
  return verifyAndRelayRequest({
    route: '/nuc/generate/printer/token',
    method: 'POST',
    protected: true,
    data: {
      serialNumber,
      printerSerialNumber
    }
  });
}

/*
 returns:
 {
   status: true
 }
*/
export async function nucAddPrinter(printerToken, name) {
  return verifyAndRelayRequest({
    route: '/nuc/add/printer',
    method: 'POST',
    protected: true,
    data: {
      printerToken,
      name
    }
  });
}

export async function nucDeviceUpdate(serialNumber, updateRelease = false, updateOS = false) {
  return verifyAndRelayRequest({
    route: '/nuc/device/update',
    method: 'POST',
    data: {
      serialNumber,
      updateRelease,
      updateOS
    },
    protected: true
  });
}

// only error this should return is initToken invalid
export async function nucPlanActivate(initializationToken) {
  return verifyAndRelayRequest({
    route: '/nuc/plan/activate',
    method: 'POST',
    data: {
      initializationToken
    },
    protected: true
  });
}

// factory reset
export async function nucReset(initializationToken) {
  return verifyAndRelayRequest({
    route: '/nuc/reset',
    method: 'POST',
    data: {
      initializationToken
    },
    protected: true
  });
}

export async function nucUserPasswordReset(initializationToken, email, newPassword) {
  return verifyAndRelayRequest({
    route: '/nuc/password/reset',
    method: 'POST',
    data: {
      initializationToken,
      email,
      newPassword
    },
    protected: false
  });
}
