import { verifyAndRelayRequest, verifyAndRelayResourcesRequest } from '../utils/index';

// export async function materialCreate(access, name, longName, code, materialData) {
//   return verifyAndRelayRequest({
//     route: '/material/create',
//     method: 'POST',
//     protected: true,
//     data: {
//       access, name, longName, code, materialData,
//     },
//   });
// }

// export async function materialRead(filter, options = {}) {
//   return verifyAndRelayRequest({
//     route: '/material/read',
//     method: 'POST',
//     data: {
//       filter,
//       options,
//     },
//     protected: true,
//   });
// }

export async function materialUpdate(filter, update) {
  return verifyAndRelayRequest({
    route: '/material/update',
    method: 'PUT',
    protected: true,
    data: {
      filter,
      update
    }
  });
}

export async function materialDelete(filter) {
  return verifyAndRelayRequest({
    route: '/material/delete',
    method: 'DELETE',
    protected: true,
    data: { filter }
  });
}

// module.exports = {
//   materialCreate,
//   materialRead,
//   materialUpdate,
//   materialDelete,
// };

export async function materialCreate(access, name, longName, code, materialData) {
  // access is unused -> will be set to PRIVATE
  // use materialChangeAccess to change access type if required

  return verifyAndRelayResourcesRequest({
    route: '/material-create',
    method: 'POST',
    protected: true,
    data: {
      material: {
        name,
        code,
        materialData,
        printerData: {
          supportedPrinters: ['1', '2', '3']
        }
      }
    }
  });
}

export async function materialRead(filter, options = {}) {
  // transform _id string to array of strings
  if (typeof filter._id === 'string' || filter._id instanceof String) {
    filter._id = [filter._id];
  }

  // transform access string to array of strings
  if (typeof filter.access === 'string' || filter.access instanceof String) {
    filter.access = [filter.access];
  }

  if (filter.printerData !== null && filter.printerData !== undefined) {
    if (filter.printerData.supportedPrinters != null && Array.isArray(filter.printerData.supportedPrinters) === false) {
      filter.printerData.supportedPrinters = [filter.printerData.supportedPrinters];
    }
  }

  return verifyAndRelayResourcesRequest({
    route: '/material-read',
    method: 'POST',
    data: {
      filter,
      options
    },
    protected: true
  });
}
