import Cookie from 'js-cookie';
import { ThunkDispatch } from 'redux-thunk';
import {
  setAuthToken,
  userRead,
  userAuthenticate,
  userCreate,
  userPasswordForgot,
  userPasswordReset,
  userGenerateFirebaseToken,
  User
} from '../../../apis/allevi-api-wrapper';

// Detect if running on-prem
const onPremDB = window.hostEnvironment && window.hostEnvironment === 'ON_PREM';

// Set auth token
export const setAPIToken = (token: string) => {
  setAuthToken(token);

  return {
    type: 'SET_API_TOKEN',
    token
  } as const;
};

// Logout User
export const logoutUser = () => {
  // Remove token from browser storage
  sessionStorage.clear();

  // Remove session token cookie
  const uiDomain = new URL(document.location.href).hostname;
  Cookie.remove('allevi-session-token', { path: '', domain: uiDomain });

  // Remove token from API wrapper
  setAuthToken(undefined);
  //@ts-expect-error
  window.location = '/login?reason=timeout';

  return {
    type: 'LOGOUT_USER'
  } as const;
};

// Get current user
// Log out on fail: JWT or API connection issue requires re-login
const getUserAction = () =>
  ({
    type: 'GET_USER'
  } as const);

const getUserSuccess = (user: User) =>
  ({
    type: 'GET_USER_SUCCESS',
    user
  } as const);

const getUserFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_USER_FAILURE',
    statusCode,
    message
  } as const);

export function getUser() {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getUserAction());

    return userRead({})
      .then(e => {
        if (e.statusCode === 200 && e.user) {
          dispatch(getUserSuccess(e.user));
          return { success: true } as const;
        }
        dispatch(getUserFailure(e.statusCode, e.message));
        logoutUser();
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getUserFailure(e.statusCode, e.message));
        logoutUser();
        return { success: false } as const;
      });
  };
}

// Authenticate user
const authenticateUserAction = (email: string, password: string) =>
  ({
    type: 'AUTHENTICATE_USER',
    email,
    password
  } as const);

const authenticateUserSuccess = (user: User) =>
  ({
    type: 'AUTHENTICATE_USER_SUCCESS',
    user
  } as const);

const authenticateUserFailure = (statusCode: number, message: string) =>
  ({
    type: 'AUTHENTICATE_USER_FAILURE',
    statusCode,
    message
  } as const);

export function authenticateUser(email: string, password: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(authenticateUserAction(email, password));

    return userAuthenticate(email, password)
      .then(e => {
        if (e.statusCode === 200 && e.token && e.user) {
          dispatch(authenticateUserSuccess(e.user));
          // Store token in browser storage
          sessionStorage.setItem('id_token', e.token);
          // Set token for API wrapper
          setAuthToken(e.token);
          return { success: true } as const;
        }
        dispatch(authenticateUserFailure(e.statusCode, e.message));
        return { success: false, ...e } as const;
      })
      .catch(e => {
        dispatch(authenticateUserFailure(e.statusCode, e.message));
        return { success: false, ...e } as const;
      });
  };
}

// // Generate temporary token
// const generateTokenAction = (email, password) => ({
//   type: 'GENERATE_TOKEN',
//   email,
//   password,
// });
//
// const generateTokenSuccess = token => ({
//   type: 'GENERATE_TOKEN_SUCCESS',
//   token,
// });
//
// const generateTokenFailure = (statusCode, message) => ({
//   type: 'GENERATE_TOKEN_FAILURE',
//   statusCode,
//   message,
// });
//
// export function generateToken() {
//   return (dispatch) => {
//     dispatch(generateTokenAction());
//
//     return userCreateToken()
//       .then(e => {
//         if (e.statusCode === 200 && e.data.token) {
//           dispatch(generateTokenSuccess(e.data.token));
//           return {
//             success: true,
//             token: e.data.token,
//           };
//         }
//         dispatch(generateTokenFailure(e.statusCode, e.message));
//         return { success: false };
//       })
//       .catch(e => {
//         dispatch(generateTokenFailure(e.statusCode, e.message));
//         return { success: false };
//       });
//   };
// }

// Generate firebase token for comms
const generateCommTokenAction = () =>
  ({
    type: 'GENERATE_COMM_TOKEN'
  } as const);

const generateCommTokenSuccess = (token?: string) =>
  ({
    type: 'GENERATE_COMM_TOKEN_SUCCESS',
    token
  } as const);

const generateCommTokenFailure = (statusCode: number, message: string) =>
  ({
    type: 'GENERATE_COMM_TOKEN_FAILURE',
    statusCode,
    message
  } as const);

export function generateCommToken() {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(generateCommTokenAction());
    if (onPremDB) {
      dispatch(generateCommTokenSuccess());
      return Promise.resolve({
        success: true,
        token: undefined as any as string
      } as const);
    }

    return userGenerateFirebaseToken()
      .then(e => {
        if (e.statusCode === 200 && e.token) {
          dispatch(generateCommTokenSuccess(e.token));
          return {
            success: true,
            token: e.token
          } as const;
        }
        dispatch(generateCommTokenFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(generateCommTokenFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Register user
const registerUserAction = (firstName: string, lastName: string, email: string, password: string) =>
  ({
    type: 'REGISTER_USER',
    firstName,
    lastName,
    email,
    password
  } as const);

const registerUserSuccess = (user: User) =>
  ({
    type: 'REGISTER_USER_SUCCESS',
    user
  } as const);

const registerUserFailure = (statusCode: number, message: string) =>
  ({
    type: 'REGISTER_USER_FAILURE',
    statusCode,
    message
  } as const);

export function registerUser(firstName: string, lastName: string, email: string, password: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(registerUserAction(firstName, lastName, email, password));

    return userCreate(email, password, { first: firstName, last: lastName })
      .then(e => {
        if (e.statusCode === 200 && e.user) {
          dispatch(registerUserSuccess(e.user));
          return { success: true, user: e.user } as const;
        }
        dispatch(registerUserFailure(e.statusCode, e.message));
        return { success: false, statusCode: e.statusCode } as const;
      })
      .catch(e => {
        dispatch(registerUserFailure(e.statusCode, e.message));
        return { success: false, statusCode: e.statusCode } as const;
      });
  };
}

// Forgot password
const forgotPasswordAction = (email: string) =>
  ({
    type: 'FORGOT_PASSWORD',
    email
  } as const);

const forgotPasswordSuccess = () =>
  ({
    type: 'FORGOT_PASSWORD_SUCCESS'
  } as const);

const forgotPasswordFailure = (statusCode: number, message: string) =>
  ({
    type: 'FORGOT_PASSWORD_FAILURE',
    statusCode,
    message
  } as const);

export function forgotPassword(email: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(forgotPasswordAction(email));

    return userPasswordForgot(email)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(forgotPasswordSuccess());
          return { success: true } as const;
        }
        dispatch(forgotPasswordFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(forgotPasswordFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Reset password
const resetPasswordAction = (token: string, password: string) =>
  ({
    type: 'RESET_PASSWORD',
    token,
    password
  } as const);

const resetPasswordSuccess = () =>
  ({
    type: 'RESET_PASSWORD_SUCCESS'
  } as const);

const resetPasswordFailure = (statusCode: number, message: string) =>
  ({
    type: 'RESET_PASSWORD_FAILURE',
    statusCode,
    message
  } as const);

export function resetPassword(token: string, password: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(resetPasswordAction(token, password));

    return userPasswordReset(token, password)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(resetPasswordSuccess());
          return { success: true } as const;
        }
        dispatch(resetPasswordFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(resetPasswordFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}
