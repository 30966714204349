import { ThunkDispatch } from 'redux-thunk';
import { AlleviAdapterState, AlleviClientState, getAlleviAdapterLogs } from '../../../apis/allevi-adapter-wrapper';
import { uploadAlleviAdapterLogs as uploadAlleviAdapterLogs_ } from '../../../apis/allevi-api-wrapper';
import { RootState } from '../reducers';

export function adapterStateReceived(state: AlleviAdapterState) {
  return { type: 'ADAPTER_STATE_RECEIVED', state } as const;
}

export function adapterNoResponse() {
  return { type: 'ADAPTER_NO_RESPONSE' } as const;
}

export function adapterClientStateReceived(state: AlleviClientState) {
  return { type: 'ADAPTER_CLIENT_STATE_RECEIVED', state } as const;
}

export function adapterClientNoResponse() {
  return { type: 'ADAPTER_CLIENT_NO_RESPONSE' } as const;
}

function uploadAlleviAdapterLogsSuccess(status: boolean) {
  return {
    type: 'UPLOAD_ALLEVI_ADAPTER_LOGS_SUCCESS',
    status
  } as const;
}

function uploadAlleviAdapterLogsFailure(statusCode: number, message: string) {
  return {
    type: 'UPLOAD_ALLEVI_ADAPTER_LOGS_FAILURE',
    statusCode,
    message
  } as const;
}

export function uploadAlleviAdapterLogs() {
  return async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const printer = getState().adapter.serialNumber;
    if (!printer) {
      return { success: false } as const;
    }
    const logs = await getAlleviAdapterLogs();
    return uploadAlleviAdapterLogs_(printer, logs)
      .then(e => {
        if (e.statusCode === 200 && e.status === true) {
          dispatch(uploadAlleviAdapterLogsSuccess(e.status));
          return {
            success: true,
            key: e.key!
          } as const;
        }
        dispatch(uploadAlleviAdapterLogsFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(uploadAlleviAdapterLogsFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}
export function downloadAlleviAdapterLogs() {
  return async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const printer = getState().adapter.serialNumber;
    if (!printer) {
      return { success: false } as const;
    }
    const logs = await getAlleviAdapterLogs();
    return { success: true, logs };
  };
}
