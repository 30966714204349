import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorsMapObject } from 'redux';
import { bindActionCreators } from '../../../util/bindActionCreators';
import * as UserActions from '../actions/userActions';
import * as OrganizationActions from '../actions/organizationActions';
import * as DeviceActions from '../actions/deviceActions';
import * as PrinterActions from '../actions/printerActions';
import * as FileActions from '../actions/fileActions';
import * as WellplateActions from '../actions/wellplateActions';
import * as CommActions from '../actions/commActions';
import * as ProjectActions from '../actions/projectActions';
import * as MaterialActions from '../actions/materialActions';
import * as NucActions from '../actions/nucActions';
import * as AdapterActions from '../actions/adapterActions';

function useActions<T extends ActionCreatorsMapObject<any>>(actions: T) {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch, actions]);
}

export function useUserActions() {
  return useActions(UserActions);
}
export function useOrganizationActions() {
  return useActions(OrganizationActions);
}
export function useDeviceActions() {
  return useActions(DeviceActions);
}
export function usePrinterActions() {
  return useActions(PrinterActions);
}
export function useFileActions() {
  return useActions(FileActions);
}
export function useWellplateActions() {
  return useActions(WellplateActions);
}
export function useCommActions() {
  return useActions(CommActions);
}
export function useProjectActions() {
  return useActions(ProjectActions);
}
export function useMaterialActions() {
  return useActions(MaterialActions);
}
export function useNucActions() {
  return useActions(NucActions);
}
export function useAdapterActions() {
  return useActions(AdapterActions);
}
