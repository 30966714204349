import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

// Material components
import { CssBaseline, Grid, Paper } from '@material-ui/core';

import { siteName, colors, din, globalStyle } from '../styles/variables';
import { breakpoints } from '../styles/breakpoints';
import { px } from '../util/style';

import OnPremBar from '../components/elements/OnPremBar';

//
// 404 Page
//
const NotFoundContainer = styled(Grid)`
  min-width: 700px;
  height: 100vh;
  min-height: ${px(600)};
  margin: 0 auto;
  padding: ${px(48)} ${px(36)} ${px(72)} ${px(36)};
  background-color: ${colors.milk2};

  ${breakpoints.down('sm')} {
    padding: ${px(36)} ${px(24)};
  }

  ${breakpoints.up('md')} {
    padding: ${px(72)} ${px(72)};
  }

  ${breakpoints.up('lg')} {
    padding: ${px(72)} ${px(144)};
  }
`;

const NotFoundBox = styled(Paper)`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border: none;
  font-family: ${din};
  font-weight: 200;
  color: ${colors.gray45};
  background: transparent;
  padding: ${px(48)};

  & h1 {
    color: ${colors.alleviRedDark};
    margin: ${px(6)} 0;
    text-align: center;
  }

  & p {
    margin: ${px(6)} 0;
    text-align: center;
  }
`;

const Allevi404Logo = styled.img`
  width: ${px(400)};
  margin-bottom: ${px(48)};
`;

const NotFoundLink = styled.a`
  font-family: ${din};
  font-weight: 400;
  color: ${colors.alleviRedDark};
`;

const NotFound = () => {
  useEffect(() => {
    document.title = `Page Not Found | ${siteName}`;
  }, []);

  return (
    <>
      <CssBaseline />

      <OnPremBar header />

      <NotFoundContainer container direction="column" justify="center" alignItems="center">
        <NotFoundBox elevation={0}>
          <Allevi404Logo src={`${process.env.PUBLIC_URL}/Allevi404.svg`} />

          <h1>The page you‘re looking for doesn‘t seem to exist</h1>

          <p>
            Try double-checking the URL, or <NotFoundLink href="/">return to our homepage</NotFoundLink>
          </p>
        </NotFoundBox>
      </NotFoundContainer>
    </>
  );
};

export default withStyles(globalStyle)(NotFound);
