import { verifyAndRelayRequest, verifyAndRelayResourcesRequest } from '../utils/index';

// export async function projectCreate(access, name, description, printer) {
//   return verifyAndRelayRequest({
//     route: '/project/create',
//     method: 'POST',
//     protected: true,
//     data: {
//       access, name, description, printer,
//     },
//   });
// }

// export async function projectRead(filter, options = {}) {
//   return verifyAndRelayRequest({
//     route: '/project/read',
//     method: 'POST',
//     data: {
//       filter,
//       options,
//     },
//     protected: true,
//   });
// }

// export async function projectUpdate(filter, update) {
//   return verifyAndRelayRequest({
//     route: '/project/update',
//     method: 'PUT',
//     protected: true,
//     data: {
//       filter,
//       update,
//     },
//   });
// }

export async function projectDelete(filter) {
  return verifyAndRelayRequest({
    route: '/project/delete',
    method: 'DELETE',
    protected: true,
    data: { filter }
  });
}

// module.exports = {
//   projectCreate,
//   projectRead,
//   projectUpdate,
//   projectDelete,
// };

/*
returns:
  experiments: Array of cloned experiments (should be len == 1 since ID is being passed in to clone)
  err: null if no error else error object
*/
export async function projectClone(experimentId, targetPrinter) {
  return verifyAndRelayResourcesRequest({
    route: '/experiment-clone',
    method: 'POST',
    protected: true,
    data: {
      filter: {
        _id: [experimentId]
      },
      options: {
        targetPrinter
      }
    }
  });
}

export async function projectCreate(access, name, description, printer) {
  // access is unused -> will be set to PRIVATE
  // use experimentChangeAccess to change access type if required
  return verifyAndRelayResourcesRequest({
    route: '/experiment-create',
    method: 'POST',
    protected: true,
    data: {
      experiment: {
        name,
        description,
        printer
      }
    }
  });
}

export async function projectRead(filter, options = {}) {
  // transform _id string to array of strings
  if (typeof filter._id === 'string' || filter._id instanceof String) {
    filter._id = [filter._id];
  }

  // transform access string to array of strings
  if (typeof filter.access === 'string' || filter.access instanceof String) {
    filter.access = [filter.access];
  }

  return verifyAndRelayResourcesRequest({
    route: '/experiment-read',
    method: 'POST',
    data: {
      filter,
      options
    },
    protected: true
  });
}

export async function projectUpdate(filter, update) {
  // transform _id string to array of strings
  if (typeof filter._id === 'string' || filter._id instanceof String) {
    filter._id = [filter._id];
  }

  // transform access string to array of strings
  if (typeof filter.access === 'string' || filter.access instanceof String) {
    filter.access = [filter.access];
  }

  return verifyAndRelayResourcesRequest({
    route: '/experiment-update',
    method: 'POST',
    protected: true,
    data: {
      filter,
      options: {},
      update
    }
  });
}
