import { combineReducers } from 'redux';

import user from './user';
import organization from './organization';
import device from './device';
import printer from './printer';
import file from './file';
import wellplate from './wellplate';
// import socket from './socket';
import comm from './comm';
import project from './project';
import material from './material';
import nuc from './nuc';
import adapter from './adapter';

const rootReducer = combineReducers({
  user,
  organization,
  device,
  printer,
  file,
  wellplate,
  // socket,
  comm,
  project,
  material,
  nuc,
  adapter
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
