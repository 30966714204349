import { ThunkDispatch } from 'redux-thunk';
import { Wellplate, wellplateRead } from '../../../apis/allevi-api-wrapper';

// Get wellplate
const getWellplateAction = (wellplateId: string) =>
  ({
    type: 'GET_WELLPLATE',
    wellplateId
  } as const);

const getWellplateSuccess = (wellplate: Wellplate) =>
  ({
    type: 'GET_WELLPLATE_SUCCESS',
    wellplate
  } as const);

const getWellplateFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_WELLPLATE_FAILURE',
    statusCode,
    message
  } as const);

export function getWellplate(wellplateId: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getWellplateAction(wellplateId));

    return wellplateRead({ _id: wellplateId })
      .then(e => {
        if (e.statusCode === 200 && e.wellplate) {
          dispatch(getWellplateSuccess(e.wellplate[0]));
          return { wellplate: e.wellplate[0], success: true } as const;
        }
        dispatch(getWellplateFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getWellplateFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Get all wellplates
const getAllWellplatesAction = () =>
  ({
    type: 'GET_ALL_WELLPLATES'
  } as const);

const getAllWellplatesSuccess = (wellplates: Wellplate[]) =>
  ({
    type: 'GET_ALL_WELLPLATES_SUCCESS',
    wellplates
  } as const);

const getAllWellplatesFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_ALL_WELLPLATES_FAILURE',
    statusCode,
    message
  } as const);

export function getAllWellplates(skip = 0, limit = 1000, sort: Record<string, number> = {}) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getAllWellplatesAction());

    return wellplateRead({}, { skip, limit, sort })
      .then(e => {
        if (e.statusCode === 200 && e.wellplates) {
          dispatch(getAllWellplatesSuccess(e.wellplates));
          return { success: true } as const;
        }
        dispatch(getAllWellplatesFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getAllWellplatesFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Get all wellplates by printer
const getAllWellplatesByPrinterAction = (modelNumber: number) =>
  ({
    type: 'GET_ALL_WELLPLATES_BY_PRINTER',
    modelNumber
  } as const);

const getAllWellplatesByPrinterSuccess = (wellplates: Wellplate[]) =>
  ({
    type: 'GET_ALL_WELLPLATES_BY_PRINTER_SUCCESS',
    wellplates
  } as const);

const getAllWellplatesByPrinterFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_ALL_WELLPLATES_BY_PRINTER_FAILURE',
    statusCode,
    message
  } as const);

export function getAllWellplatesByPrinter(modelNumber: number) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getAllWellplatesByPrinterAction(modelNumber));

    return wellplateRead({ printerData: { supportedPrinters: [modelNumber] } })
      .then(e => {
        if (e.statusCode === 200 && e.wellplates) {
          dispatch(getAllWellplatesByPrinterSuccess(e.wellplates));
          return { success: true } as const;
        }
        dispatch(getAllWellplatesByPrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        dispatch(getAllWellplatesByPrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}
