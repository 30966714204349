import { sendRequest, verifyAndRelayRequest, setAuthToken } from '../utils/index';

export async function userAuthenticate(email, password) {
  const authResponse = await sendRequest({
    route: '/user/authenticate',
    method: 'POST',
    protected: false,
    data: { email, password }
  });
  setAuthToken(authResponse.token);
  return authResponse;
}

export async function userCreate(email, password, name) {
  return verifyAndRelayRequest({
    route: '/user/create',
    method: 'POST',
    protected: true,
    data: {
      email,
      password,
      name
    }
  });
}

export async function userRead() {
  return verifyAndRelayRequest({
    route: '/user/read',
    method: 'GET',
    protected: true
  });
}

export async function userUpdate(update) {
  return verifyAndRelayRequest({
    route: '/user/update',
    method: 'PUT',
    protected: true,
    data: {
      update
    }
  });
}

export async function userDelete(filter) {
  return verifyAndRelayRequest({
    route: '/user/delete',
    method: 'DELETE',
    protected: true,
    data: { filter }
  });
}

export async function userGenerateFirebaseToken() {
  return verifyAndRelayRequest({
    route: '/user/generate/firebase/token',
    method: 'GET',
    protected: true
  });
}

export async function userPasswordForgot(email) {
  return verifyAndRelayRequest({
    route: '/user/password/forgot',
    method: 'POST',
    data: {
      email
    }
  });
}

export async function userPasswordReset(resetPasswordToken, password) {
  return verifyAndRelayRequest({
    route: '/user/password/reset',
    method: 'POST',
    data: {
      resetPasswordToken,
      password
    }
  });
}

export async function userPasswordUpdate(currentPassword, newPassword) {
  return verifyAndRelayRequest({
    route: '/user/password/update',
    method: 'POST',
    protected: true,
    data: {
      currentPassword,
      newPassword
    }
  });
}
