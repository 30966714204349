import { useEffect } from 'react';
import { getAlleviAdapterState, getAlleviClientState } from '../apis/allevi-adapter-wrapper';
import { useAdapterActions } from '../state/redux/hooks';

export function AlleviAdapterConnection() {
  const { adapterNoResponse, adapterStateReceived, adapterClientStateReceived, adapterClientNoResponse } =
    useAdapterActions();

  //poll for adapter availability
  useEffect(() => {
    const abortController = new AbortController();
    async function pingAlleviAdapterOnce() {
      try {
        const res = await getAlleviAdapterState(abortController.signal);
        if (abortController.signal.aborted) return;
        adapterStateReceived(res);
      } catch (e) {
        if (abortController.signal.aborted) return;
        adapterNoResponse();
      }
    }
    const interval = setInterval(pingAlleviAdapterOnce, 10 * 1000);
    abortController.signal.addEventListener('abort', () => clearInterval(interval));
    pingAlleviAdapterOnce();
    return () => abortController.abort();
  }, [adapterNoResponse, adapterStateReceived]);

  //poll for client availability
  useEffect(() => {
    const abortController = new AbortController();
    async function pingAlleviClientOnce() {
      try {
        const res = await getAlleviClientState(abortController.signal);
        if (abortController.signal.aborted) return;
        adapterClientStateReceived(res);
      } catch (e) {
        if (abortController.signal.aborted) return;
        adapterClientNoResponse();
      }
    }
    const interval = setInterval(pingAlleviClientOnce, 10 * 1000);
    abortController.signal.addEventListener('abort', () => clearInterval(interval));
    pingAlleviClientOnce();
    return () => abortController.abort();
  }, [adapterClientNoResponse, adapterClientStateReceived]);

  return null;
}
